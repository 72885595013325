import React, { useState } from "react";
import {
  PasswordField,
  SocialOptions,
  EmailField,
  LoginSignupButton,
} from "./loginModal.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import QuizApi from "../services/QuizApi.js";

export default function SignupModal(props) {
  const [formFields, setFormFields] = useState({
    first_name: "",
    username: "",
    password: "",
  });

  const [registrationMessage, setRegistrationMessage] = useState("");

  const handleFirstNameChange = (e) => {
    setFormFields({ ...formFields, first_name: e.target.value });
    setRegistrationMessage("");
  };

  const handleUsernameChange = (e) => {
    setFormFields({ ...formFields, username: e.target.value });
    setRegistrationMessage("");
  };

  const handlePasswordChange = (e) => {
    setFormFields({ ...formFields, password: e.target.value });
    setRegistrationMessage("");
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailRegex.test(email);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (formFields.first_name.trim() === "") {
      setRegistrationMessage("Please enter your full name.");
      return;
    }

    if (formFields.password.length < 8) {
      setRegistrationMessage(
        "Please enter a password with 8 or more characters.",
      );
      return;
    }

    if (!isEmailValid(formFields.username)) {
      setRegistrationMessage("Please enter a valid email address.");
      return;
    }

    try {
      const signupData = await QuizApi.post("/register/", formFields);

      if (signupData.status === 200 || signupData.status === 201) {
        setRegistrationMessage("Registration Successful!");
        setTimeout(() => {
          setRegistrationMessage("");
          props.onOpen();
          props.onClose();
        }, 2000);
      } else if (signupData.status === 403) {
        setRegistrationMessage(
          "The email you entered is already associated with an account.",
        );
      } else {
        setRegistrationMessage(
          "The email you entered is already associated with an account.",
        );
      }
    } catch (error) {
      setRegistrationMessage(
        "The email you entered is already associated with an account.",
      );
    }
  };

  return (
    <div
      className="outside-column"
      style={{
        border: "1px solid #cecece",
        height: "auto",
        padding: 10,
        marginBottom: 10,
      }}
    >
      <FontAwesomeIcon
        style={{ cursor: "pointer", position: "absolute" }}
        icon={faClose}
        className="close-icon"
        onClick={props.onClose}
      />
      <div style={{ paddingLeft: 40, paddingRight: 40, height: "auto" }}>
        <div
          style={{ width: "100%", marginBottom: 10 }}
          className="logintittle"
        >
          <h5
            id="h5"
            style={{
              fontSize: 24,
              fontStyle: "normal",
              fontWeight: 600,
              letterSpacing: "0.01em",
              color: "#052949",
              textAlign: "center !important",
              marginTop: 17,
              marginBottom: 25,
            }}
          >
            Sign Up. It's Quick and Easy.
          </h5>
        </div>
        <div id="form">
          <form id="signupform" onSubmit={handleFormSubmit}>
            <div className="form-outline ">
              <input
                type="text"
                name="first_name"
                id="first_name"
                className="form-control-m border-top-0 border-right-0 border-left-0 inputtypeemail"
                placeholder="Enter your full name"
                required={true}
                value={formFields.first_name}
                onChange={handleFirstNameChange}
              />
            </div>
            <EmailField
              placeholder="Enter your work email"
              value={formFields.username}
              onChange={handleUsernameChange}
            />
            <PasswordField
              placeholder="Create a password"
              value={formFields.password}
              onChange={handlePasswordChange}
            />
            <br></br>
            {registrationMessage && (
              <div
                className={`registration-message ${
                  registrationMessage === "Registration Successful!"
                    ? "success_msg"
                    : "error_msg"
                }`}
              >
                {registrationMessage}
              </div>
            )}

            <LoginSignupButton
              text="Create Account"
              onClick={handleFormSubmit}
            />
          </form>
        </div>
        <div className="alreadyhaveaccount">
          <div>
            Already have an account?{" "}
            <button
              className="loginsignnup_btn"
              href=""
              onClick={() => {
                props.onOpen();
                props.onClose();
              }}
            >
              Login
            </button>
          </div>
        </div>
        <div className="separator">Or</div>
        <SocialOptions />
      </div>
    </div>
  );
}
