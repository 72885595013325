import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import ContentEditable from "react-contenteditable";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../state";
import {
  handleDeleteQuestion,
  handleQuestionSubmit,
} from "../../../utils/helpers";

export default function MultipleChoice(props) {
  const defaultOptions = { opt_1: "", opt_2: "" };
  const [propsValues, setPropsValues] = useState({
    ...props.data,
    options: props.data.options || defaultOptions,
  });
  const [optionMoreThanTwo, setOptionMoreThanTwo] = useState(false);

  const dispatch = useDispatch();
  const { setQuizQuestions } = bindActionCreators(actionCreators, dispatch);
  const setQuestions = useSelector((state) => state.setQuestions);

  useEffect(() => {
    if (!propsValues.options) {
      setPropsValues((prev) => ({ ...prev, options: defaultOptions }));
    }
  }, [propsValues.options]);

  const handleAnswerChange = (event) => {
    const selectedValue = event.target.value;
    setPropsValues((prev) => ({
      ...prev,
      correct_answers: [selectedValue],
    }));
    handleQuestionSubmit(setQuestions, {
      correct_answers: [selectedValue],
    });
  };

  const handleOptionEdit = (event) => {
    const { id, innerHTML } = event.currentTarget;

    setPropsValues((prev) => ({
      ...prev,
      options: { ...prev.options, [id]: `<pre>${innerHTML.trim()}</pre>` },
    }));
  };

  const handleOptionBlur = (event) => {
    const opt_key = event.currentTarget.id;
    const value = event.currentTarget.innerHTML;

    if (value.trim() !== "") {
      handleQuestionSubmit(setQuestions, {
        options: {
          ...propsValues.options,
          [opt_key]: `<pre>${value.trim()}</pre>`,
        },
      });
    }
  };

  const handleAddAdditionalOptions = () => {
    const current_option_count = Object.keys(propsValues.options).length + 1;
    const newKey = `opt_${current_option_count}`;
    setPropsValues((prev) => ({
      ...prev,
      options: { ...prev.options, [newKey]: "" },
    }));
  };

  const removeOptions = (key) => {
    setPropsValues((prev) => {
      const { [key]: removedOption, ...restOptions } = prev.options;
      return { ...prev, options: restOptions };
    });
    handleQuestionSubmit(setQuestions, {
      options: { ...propsValues.options },
      correct_answers: [...propsValues.correct_answers],
    });
  };

  useEffect(() => {
    const opt_list = Object.values(propsValues.options || {});
    const fill_count = opt_list.filter((opt) => opt && opt.length >= 1).length;
    setOptionMoreThanTwo(fill_count > 1);
  }, [propsValues.options]);

  // Function to remove extra CSS from pasted content
  const removeExtraCss = (element) => {
    const elementTree = element.getElementsByTagName("*");

    for (let i = 0; i < elementTree.length; i++) {
      const ele = elementTree[i];
      if (ele.tagName === "INPUT") {
        continue;
      }
      ele.removeAttribute("style");
      ele.removeAttribute("class");
    }
  };

  // Updated handleOnPaste function to use removeExtraCss
  const handleOnPaste = (event, from) => {
    if (event.clipboardData.types.includes("Files")) {
      event.preventDefault();
      return;
    }
    if (event.clipboardData.types.includes("text/html")) {
      event.preventDefault();
      let value = event.clipboardData.getData("text/html");
      let tempElement = document.createElement("div");
      tempElement.innerHTML = value;
      removeExtraCss(tempElement);
      switch (from) {
        case "question_title":
          setPropsValues((prevValues) => ({
            ...prevValues,
            question_title: `<pre>${tempElement.innerHTML.trim()}</pre>`,
          }));
          break;
        case "options":
          setPropsValues((prevValues) => ({
            ...prevValues,
            options: {
              ...prevValues.options,
              [event.currentTarget.id]:
                `<pre>${tempElement.innerHTML.trim()}</pre>`,
            },
          }));
          break;
        default:
          break;
      }
    }
  };
  return (
    <div className="main-multiple-choice-questions question-main-container">
      <div className="question-title">
        <div className="question-title-head">
          Multiple Choice Question
          <span className="total-questions-of">1/10</span>
        </div>
        <ContentEditable
          onFocus={(event) =>
            window.getSelection().selectAllChildren(event.target)
          }
          className="question-title-body"
          html={
            propsValues.question_title
              ? `<pre>${propsValues.question_title}</pre>`
              : ""
          }
          onChange={(event) => {
            const value = event.target.value;
            setPropsValues((prev) => ({ ...prev, question_title: value }));
          }}
          onBlur={(event) =>
            handleQuestionSubmit(setQuestions, {
              question_title: event.target.innerHTML,
            })
          }
          placeholder="Enter your Multiple Choice Question here.."
          onPaste={(event) => handleOnPaste(event, "question_title")}
        />
        <FontAwesomeIcon
          icon={faTrashCan}
          className="question-delete-icon"
          size="xl"
          onClick={() => handleDeleteQuestion(setQuestions)}
        />
      </div>

      <div className="question-choices">
        <div className="question-choices-head">
          {optionMoreThanTwo ? "Select Correct Answer" : "Enter the Answers"}
        </div>
        <div className="question-choices-body">
          {Object.entries(propsValues.options || {}).map(
            ([key, value], index) => (
              <div className="choice" key={key}>
                <span>
                  <input
                    type="radio"
                    name="choice"
                    className="btn-radio"
                    value={key}
                    checked={propsValues.correct_answers.includes(key)}
                    onChange={handleAnswerChange}
                  />
                  <ContentEditable
                    onFocus={(event) =>
                      window.getSelection().selectAllChildren(event.target)
                    }
                    html={value}
                    placeholder={`Answer Number ${index + 1}`}
                    tagName="div"
                    className="input-ans"
                    id={key}
                    onChange={handleOptionEdit}
                    onBlur={handleOptionBlur}
                    onPaste={(event) => handleOnPaste(event, "options")}
                  />
                </span>
                {Object.keys(propsValues.options).length > 2 && (
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    size="lg"
                    className="trash"
                    style={{ color: "#002653" }}
                    onClick={() => removeOptions(key)}
                  />
                )}
              </div>
            ),
          )}
          <button className="btn-with-img" onClick={handleAddAdditionalOptions}>
            <FontAwesomeIcon icon={faPlus} size="lg" /> Add Additional Answer
            Choice
          </button>
        </div>
      </div>
    </div>
  );
}
