import "./App.css";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import QuizSettings from "./components/Settings/GeneralSettings";
import BrowserSettings from "./components/Settings/BrowserSettings";
import ScoringOption from "./components/Settings/ScoringOptions";
import QuizTracking from "./components/Settings/QuizTracking";
import SmartProctoring from "./components/Settings/SmartProctoring";
import LeadIntegration from "./components/Settings/LeadIntegration";
import QuizTitle from "./components/QuizTitle";
import Home from "./components/Home";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Resultpage from "./components/ResultPageSettings";
// import { QuizResultPage} from "./components/QuizResultPage";
import QuizResultPage from "./components/QuizResult";
import QuizStats from "./components/QuizAnalysis/QuizStats";
import { ReportPage } from "./components/QuizReport";
import Cookies from "js-cookie";
import { QuizList } from "./components/ManageQuiz";
import "./App.css";
import EditQuiz from "./components/editQuiz/EditQuiz";
import EditQuestions from "./components/editQuiz/EditQuestions";
import TakeQuizMain from "./components/takeQuiz/TakeQuizMain";
import "./assets/styles/commonQuestions.css";
import "./assets/styles/index.css";
import "./assets/styles/Editquiz.css";
import QuizReport from "./components/QuizReport";

library.add(fas);

function App() {
  let userData = { user: {} };
  const encodedData = Cookies.get("authToken");
  if (encodedData) {
    const decodedData = decodeURIComponent(encodedData);
    userData = JSON.parse(decodedData);
  }

  const [isLoggedIn, setIsLoggedIn] = useState(userData.token);

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home setIsLoggedIn={setIsLoggedIn} />} />
          <Route
            path="/login"
            element={<Home setIsLoggedIn={setIsLoggedIn} />}
          />
          <Route
            path="/register"
            element={<Home setIsLoggedIn={setIsLoggedIn} />}
          />
          <Route path="/quiz-title/:quizId" element={<QuizTitle />} />
          <Route path="/quiz/:quizId" element={<EditQuestions />} />
          <Route exact path="/edit-quiz" element={<EditQuiz />} />
          <Route exact path="/edit-questions" element={<EditQuestions />} />
          <Route
            path="/manage"
            element={isLoggedIn ? <QuizList /> : <Navigate to="/" />}
          />
          <Route
            path="/manage/:page"
            element={isLoggedIn ? <QuizList /> : <Navigate to="/" />}
          />
          <Route
            path="/general-settings/:quizId"
            element={isLoggedIn ? <QuizSettings /> : <Navigate to="/" />}
          />
          <Route
            path="/security-settings/:quizId"
            element={isLoggedIn ? <BrowserSettings /> : <Navigate to="/" />}
          />
          <Route
            path="/scoring-option/:quizId"
            element={isLoggedIn ? <ScoringOption /> : <Navigate to="/" />}
          />
          <Route
            path="/quiz-tracking/:quizId"
            element={isLoggedIn ? <QuizTracking /> : <Navigate to="/" />}
          />
          <Route
            path="/smart-proctoring-system/:quizId"
            element={isLoggedIn ? <SmartProctoring /> : <Navigate to="/" />}
          />
          <Route
            path="/lead-integration/:quizId"
            element={isLoggedIn ? <LeadIntegration /> : <Navigate to="/" />}
          />
          <Route
            path="/view-result-settings/:quizId"
            element={isLoggedIn ? <Resultpage /> : <Navigate to="/" />}
          />
          <Route
            path="/quiz-result/:quizId"
            element={isLoggedIn ? <QuizResultPage /> : <Navigate to="/" />}
          />
          <Route
            path="/quiz-result/:quizId/:page"
            element={isLoggedIn ? <QuizResultPage /> : <Navigate to="/" />}
          />
          <Route
            path="/quiz-stats/:quizId"
            element={isLoggedIn ? <QuizStats /> : <Navigate to="/" />}
          />
          <Route
            path="/quiz-report/:quizId/:id"
            element={isLoggedIn ? <QuizReport /> : <Navigate to="/" />}
          />
          <Route path="preview/:quizId" element={<TakeQuizMain />} />
          {/* Add more routes as needed */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
