import "../assets/styles/homePage.css";

import { useState } from "react";
import Modal from "react-modal";
import $ from "jquery";
import { Navbar } from "./Common/Navbar";
import LoginModal from "./loginModal";
import SignupModal from "./SignupModal";
import { useNavigate } from "react-router-dom";

export default function HomePage(props) {
  const [isLoginPopupOpen, setLoginIsPopupOpen] = useState(false);
  const [isSignupPopupOpen, setSignupIsPopupOpen] = useState(false);
  const navigate = useNavigate();
  const openLoginPopup = () => {
    setLoginIsPopupOpen(true);
  };

  const closeLoginPopup = () => {
    setLoginIsPopupOpen(false);
  };
  const openSignupPopup = () => {
    setSignupIsPopupOpen(true);
  };

  const closeSignupPopup = () => {
    setSignupIsPopupOpen(false);
  };

  return (
    <div>
      <Navbar onOpen={openLoginPopup} openSignupPopup={openSignupPopup} />
      <Modal
        isOpen={isLoginPopupOpen}
        onRequestClose={closeLoginPopup}
        contentLabel="Login Popup"
        ariaHideApp={false}
      >
        <LoginModal
          onClose={closeLoginPopup}
          onOpen={openSignupPopup}
          setIsLoggedIn={props?.setIsLoggedIn}
        />
      </Modal>
      <Modal
        isOpen={isSignupPopupOpen}
        onRequestClose={closeSignupPopup}
        contentLabel="Signup Popup"
        ariaHideApp={false}
      >
        <SignupModal onClose={closeSignupPopup} onOpen={openLoginPopup} />
      </Modal>
      <BannerImage />
      <div className="container-fluid Main_page_quotes" id="Our_Features">
        <h6 className="Text-center">Generate Quizzes for Any Requirement</h6>
      </div>
      <RequirementDetails />
      <button
        id="create_quiz"
        style={{ margin: "auto", marginTop: 45 }}
        className="btn btn-primary createQuizBtn"
      >
        <strong className="createQuizBtnText">Create a Quiz</strong>
      </button>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="container-fluid Main_page_quotes">
        <h6 className="Text-center">Video Response Question</h6>
      </div>
      <br />
      <br />
      <VideoResponseQuestion />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="container-fluid Main_page_quotes">
        <h6 className="Text-center">
          Security and Integrity of Online Quizzes
        </h6>
      </div>
      <br />
      <br />
      <SecurityIntegrationFeature />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div id="loginmodal" className="modalmain" tabIndex={-1} role="dialog">
        <div className="modal-dialog2 " role="document">
          <div className="row">
            <div className="column"></div>
          </div>
        </div>
      </div>

      <div className="container-fluid Main_page_quotes">
        <h6 className="Text-center">
          Types of Questions For Effective Assesment
        </h6>
      </div>
      <div
        className="courousalstart"
        style={{ backgroundColor: "#fff", marginTop: 5, position: "relative" }}
      >
        <div className="container-fluid quizmainslider">
          <div className="container_slider" />
          <div className="cards-wrapper">
            <ul className="cards__container">
              <div className="box">
                <div
                  className="mcqcontainer"
                  id="main-page_image"
                  style={{ textAlign: "center" }}
                >
                  <h3>Yes or No Question</h3>
                  <p>
                    Yes or No Questions are composed of a <br /> statement.
                    Allow respondents to indicate <br /> whether the statement
                    is yes or no..
                  </p>
                  <h3
                    className="templateExampleSubText"
                    style={{
                      fontSize: 18,
                      paddingTop: "4%",
                    }}
                  >
                    <pre>Example :-</pre>
                  </h3>
                  <h3
                    className="templateExampleSubText"
                    style={{
                      fontSize: 20,
                      color: "#052949",
                    }}
                  >
                    The moon is always seen at night.
                  </h3>
                  <ul>
                    <li className="mcqRadioTemplateList">
                      <input
                        type="radio"
                        name="opinions"
                        id="radio-14"
                        tabIndex={-1}
                      />
                      <label
                        className="radioLabelQuestionTemplate"
                        htmlFor="radio-14"
                      >
                        Yes
                      </label>
                    </li>
                    <li className="mcqRadioTemplateList">
                      <input
                        type="radio"
                        name="opinions"
                        id="radio-24"
                        tabIndex={-1}
                      />
                      <label
                        className="radioLabelQuestionTemplate"
                        htmlFor="radio-24"
                      >
                        {" "}
                        No{" "}
                      </label>
                    </li>
                    <li>
                      <label htmlFor="radio-24" />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="box">
                <div
                  className="mcqcontainer"
                  id="main-page_image"
                  style={{ textAlign: "center" }}
                >
                  <h3>Multiple Choice Question</h3>
                  <p>
                    Allows you to provide multiple possible <br /> answers in
                    which only one is correct.
                  </p>
                  <h3
                    className="templateExampleSubText"
                    style={{
                      fontSize: 18,
                      paddingTop: "4%",
                    }}
                  >
                    <pre>Example :-</pre>
                  </h3>
                  <h3
                    className="templateExampleSubText"
                    style={{
                      fontSize: 20,
                      color: "#052949",
                    }}
                  >
                    The complement of blue is ?
                  </h3>
                  <ul>
                    <li className="mcqRadioTemplateList">
                      <input
                        type="radio"
                        name="opinions"
                        id="radio-11"
                        tabIndex={-1}
                      />
                      <label
                        className="radioLabelQuestionTemplate"
                        htmlFor="radio-11"
                      >
                        Red
                      </label>
                    </li>
                    <li className="mcqRadioTemplateList">
                      <input
                        type="radio"
                        name="opinions"
                        id="radio-2"
                        tabIndex={-1}
                      />
                      <label
                        className="radioLabelQuestionTemplate"
                        htmlFor="radio-2"
                      >
                        Orange
                      </label>
                    </li>
                    <li className="mcqRadioTemplateList">
                      <input
                        type="radio"
                        name="opinions"
                        id="radio-3"
                        tabIndex={-1}
                      />
                      <label
                        className="radioLabelQuestionTemplate"
                        htmlFor="radio-3"
                      >
                        Green
                      </label>
                    </li>
                    <li className="mcqRadioTemplateList">
                      <input
                        type="radio"
                        name="opinions"
                        id="radio-4"
                        tabIndex={-1}
                      />
                      <label
                        className="radioLabelQuestionTemplate"
                        htmlFor="radio-4"
                      >
                        Black
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="box">
                <div
                  className="mcqcontainer"
                  id="main-page_image"
                  style={{ textAlign: "center" }}
                >
                  <h3>True or False Question</h3>
                  <p>
                    True or False Questions are composed of a <br /> statement.
                    Allow respondents to indicate <br /> whether the statement
                    is true or false..
                  </p>
                  <h3
                    className="templateExampleSubText"
                    style={{
                      fontSize: 18,
                      paddingTop: "4%",
                    }}
                  >
                    <pre>Example :-</pre>
                  </h3>
                  <h3
                    className="templateExampleSubText"
                    style={{
                      fontSize: 20,
                      color: "#052949",
                    }}
                  >
                    The moon is always seen at night.
                  </h3>
                  <ul>
                    <li className="mcqRadioTemplateList">
                      <input
                        type="radio"
                        name="opinions"
                        id="radio-12"
                        tabIndex={-1}
                      />
                      <label
                        className="radioLabelQuestionTemplate"
                        htmlFor="radio-12"
                      >
                        True
                      </label>
                    </li>
                    <li className="mcqRadioTemplateList">
                      <input
                        type="radio"
                        name="opinions"
                        id="radio-22"
                        tabIndex={-1}
                      />
                      <label
                        className="radioLabelQuestionTemplate"
                        htmlFor="radio-22"
                      >
                        False
                      </label>
                    </li>
                    <li>
                      <label htmlFor="radio-22" />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="box">
                <div
                  className="mcqcontainer"
                  id="main-page_image"
                  style={{ textAlign: "center" }}
                >
                  <h3>Essay Question</h3>
                  <p>
                    Allow respondents to answer a question <br /> a detailed
                    text based answer.
                  </p>
                  <h3
                    className="templateExampleSubText"
                    style={{
                      fontSize: 18,
                      paddingTop: "4%",
                    }}
                  >
                    <pre>Example :-</pre>
                  </h3>
                  <h3
                    className="templateExampleSubText"
                    style={{
                      fontSize: 20,
                      color: "#052949",
                    }}
                  >
                    Who is your role model and describe why? Describe in 200
                    words
                  </h3>
                  <textarea
                    className="EssayQuestionTemplate"
                    placeholder="Start your essay..."
                    tabIndex={-1}
                    name="textarea"
                    defaultValue={""}
                  />
                </div>
              </div>
              <div className="box">
                <div
                  className="mcqcontainer"
                  id="main-page_image"
                  style={{ textAlign: "center" }}
                >
                  <h3>Multiple Response Question</h3>
                  <p>
                    Allows you to provide multiple answers where <br /> more
                    than one answer is correct.
                  </p>
                  <h3
                    className="templateExampleSubText"
                    style={{
                      fontSize: 18,
                      paddingTop: "4%",
                    }}
                  >
                    <pre>Example :-</pre>
                  </h3>
                  <h3
                    className="templateExampleSubText"
                    style={{
                      fontSize: 20,
                      color: "#052949",
                    }}
                  >
                    What are your hobbies ?
                  </h3>
                  <ul id="mrq">
                    <li>
                      <input
                        type="checkbox"
                        name="opinions"
                        id="radio-13"
                        tabIndex={-1}
                      />
                      <label
                        className="checkboxLabelQuestionTemplate"
                        htmlFor="radio-13"
                      >
                        Reading
                      </label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        name="opinions"
                        id="radio-23"
                        tabIndex={-1}
                      />
                      <label
                        className="checkboxLabelQuestionTemplate"
                        htmlFor="radio-23"
                      >
                        Writing
                      </label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        name="opinions"
                        id="radio-33"
                        tabIndex={-1}
                      />
                      <label
                        className="checkboxLabelQuestionTemplate"
                        htmlFor="radio-33"
                      >
                        Singing
                      </label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        name="opinions"
                        id="radio-43"
                        tabIndex={-1}
                      />
                      <label
                        className="checkboxLabelQuestionTemplate"
                        htmlFor="radio-43"
                      >
                        Dancing
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </ul>
            <div className="card__text-content"></div>
          </div>
          <div className="cardTextContentInside" />
          <div className="home_carousel">
            <div className="button leftbtn" onClick={shiftRight}>
              <svg viewBox="0 0 256 512" width={100} title="angle-left">
                <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z" />
              </svg>
            </div>
            <div className="button rightbtn" onClick={shiftLeft}>
              <svg
                viewBox="0 0 256 512"
                width={100}
                title="angle-right"
                style={{ paddingLeft: 7 }}
              >
                <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z" />
              </svg>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        {/* pricing */}
        <PricingCards />
        <FAQ />
        {/* footer */}
        <footer className="new_footer_area bg_color">
          <div className="new_footer_top">
            <div className="container-footer">
              <div className="row">
                <div className="div_footer">
                  <div className="footer_content ">
                    <ul className="list-unstyled f_list">
                      <li>
                        <a id="link3" href="#root">
                          Home
                        </a>
                      </li>
                      <li>
                        <a id="link2" href="#Our_Pricing">
                          Pricing
                        </a>
                      </li>
                      <li>
                        <a id="link3" href="#Our_Features">
                          Features
                        </a>
                      </li>
                      <li>
                        <a id="link3" href="#header" disabled>
                          Contact Us
                        </a>
                      </li>
                    </ul>
                    <p className="mb-0 f_400" style={{ textAlign: "center" }}>
                      Copyright © 2023 | All rights reserved.
                    </p>
                  </div>
                </div>
                <div className="div_footer">
                  <div className="footer_content social-widget ">
                    <div className="f_social_icon"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

$(document).ready(function () {
  $("a").on("click", function (event) {
    if (this.hash !== "") {
      event.preventDefault();
      var hash = this.hash;
      $("html, body").animate(
        {
          scrollTop: $(hash).offset().top,
        },
        800,
        function () {
          window.location.hash = hash;
        },
      );
    }
  });
});

function shiftLeft() {
  const boxes = document.querySelectorAll(".box");
  const tmpNode = boxes[0];
  boxes[0].className = "box move-out-from-left";

  setTimeout(function () {
    if (boxes.length > 5) {
      tmpNode.classList.add("box--hide");
      boxes[5].className = "box move-to-position5-from-left";
    }
    boxes[1].className = "box move-to-position1-from-left";
    boxes[2].className = "box move-to-position2-from-left";
    boxes[3].className = "box move-to-position3-from-left";
    boxes[4].className = "box move-to-position4-from-left";
    boxes[0].remove();

    document.querySelector(".cards__container").appendChild(tmpNode);
  }, 100);
}

function shiftRight() {
  const boxes = document.querySelectorAll(".box");
  boxes[4].className = "box move-out-from-right";
  setTimeout(function () {
    const noOfCards = boxes.length;
    if (noOfCards > 4) {
      boxes[4].className = "box box--hide";
    }

    const tmpNode = boxes[noOfCards - 1];
    tmpNode.classList.remove("box--hide");
    boxes[noOfCards - 1].remove();
    let parentObj = document.querySelector(".cards__container");
    parentObj.insertBefore(tmpNode, parentObj.firstChild);
    tmpNode.className = "box move-to-position1-from-right";
    boxes[0].className = "box move-to-position2-from-right";
    boxes[1].className = "box move-to-position3-from-right";
    boxes[2].className = "box move-to-position4-from-right";
    boxes[3].className = "box move-to-position5-from-right";
  }, 100);
}

function BannerImage() {
  return (
    <div className="container Main_page_quotes">
      <div className="mainPageContainerInside">
        <h1 className="text-leftmain">
          Create quizzes your way in minutes with an easy Quiz Secure!
        </h1>
        <div>
          <p className="text-left">
            Create a quiz, then qualify and segment leads based on quiz
            responses. Easy - no coding required.
          </p>

          <button id="create_quiz_btn">
            <strong>Create your quiz for free</strong>
          </button>
          <button className="takeTourBtn">
            <strong>
              &nbsp;Take a tour &nbsp;
              <img
                style={{ width: 20 }}
                src="/images/rightarrowicon.png"
                alt=""
              />
            </strong>
          </button>
        </div>
      </div>
      <div className="hero_section" style={{ paddingRight: 10 }}>
        <img id="Hero_img" src="/images/demo_pic.jpg" />
        <div className="Hero_div">
          <button>
            <strong>
              <img src="/images/play.png" />
            </strong>
          </button>
        </div>
      </div>
    </div>
  );
}

function RequirementDetails() {
  return (
    <div className="Requirement-details">
      <article className="article_list" style={{ marginLeft: 60 }}>
        <img
          className="imgRequirementDetails"
          style={{ width: 30 }}
          src="/images/training.png"
        />
        Train your staff with interactive quizzes
      </article>
      <article className="article_list">
        <img
          className="imgRequirementDetails"
          style={{ width: 30 }}
          src="/images/quiz_q.png"
        />
        Generate leads with engaging quizzes
      </article>
      <article className="article_list" style={{ marginRight: 60 }}>
        <img
          className="imgRequirementDetails"
          style={{ width: 30 }}
          src="/images/test.png"
        />
        Test candidates before hiring them
      </article>
      <article className="article_list">
        <img
          className="imgRequirementDetails"
          style={{ width: 30 }}
          src="/images/evaluate.png"
        />
        Evaluate compliance knowledge and skills
      </article>
      <article className="article_list">
        <img
          className="imgRequirementDetails"
          style={{ width: 35 }}
          src="/images/security.png"
        />
        Conduct online exams with security and integrity
      </article>
      <article className="article_list">
        <img
          className="imgRequirementDetails"
          style={{ width: 30 }}
          src="/images/personality.png"
        />
        Discover personality traits and preferences
      </article>
    </div>
  );
}

function VideoResponseQuestion() {
  return (
    <div className="container-fluid videoResponseContainer">
      <div className="row" style={{ padding: 50 }}>
        <div className="col-md-6">
          <img
            src="/images/demo_videoss.png"
            className="img-fluid rounded-top-right videoResponseImage"
            alt="Your Image"
          />
        </div>
        <div className="col-md-6">
          <h6 id="home_h6" className="Text-center">
            You can now submit your answers in the form of a video directly
            during the quiz. The unique feature sets us apart from our
            competitors and allows for a more interactive and engaging quiz
            experience.
          </h6>
          <button id="create_quiz" className="btn btn-primary createQuizBtn">
            <strong className="createQuizBtnText">Create a Quiz</strong>
          </button>
        </div>
      </div>
    </div>
  );
}

function SecurityIntegrationFeature() {
  return (
    <div className="container-fluid securityIntegrationContainer">
      <div className="row securityIntegrationRow">
        <div className="col-md-6">
          <div className="image-row">
            <img
              className="hide-opacity"
              src="/images/eye.png"
              alt="Your Image"
            />
            <img
              src="/images/shuffle.png"
              className="img-fluid rounded-top-right"
              alt="Your Image"
            />
            <img
              className="hide-opacity"
              src="/images/eye.png"
              alt="Your Image"
            />
          </div>
          <div className="image-row">
            <img
              src="/images/eye.png"
              className="img-fluid rounded-top-right"
              alt="Your Image"
            />
            <img
              style={{ padding: 0, backgroundColor: "transparent" }}
              src="/images/gear.png"
              className="img-fluid rounded-top-right"
              alt="Your Image"
            />
            <img
              src="/images/shield.png"
              className="img-fluid rounded-top-right"
              alt="Your Image"
            />
          </div>
          <div className="image-row" style={{ marginBottom: 0 }}>
            <img
              className="hide-opacity"
              src="/images/eye.png"
              alt="Your Image"
            />
            <img
              src="/images/no_access.png"
              className="img-fluid rounded-top-right"
              alt="Your Image"
            />
            <img
              className="hide-opacity"
              src="/images/eye.png"
              alt="Your Image"
            />
          </div>
        </div>
        <div id="feature_list_home" className="col-md-6">
          <h6 id="home_h6" className="Text-center">
            You can conduct secure exams with ease. Our verified sign-on
            features ensure that only authorized users can take the exam. With
            our anti-cheating settings such as proctoring and shuffling helps in
            maintaining the integrity of the exam you create.
          </h6>
          <div>
            <button id="create_quiz" className="btn btn-primary createQuizBtn">
              <strong className="createQuizBtnText">Create a Quiz</strong>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function PricingCards() {
  const [monthly, togglePricingButton] = useState(true);

  return (
    <div id="Our_Pricing" className="pricing_section">
      <div className="container-fluid Main_page_quotes">
        <h6 className="Text-center chooseYourPlanText">
          Choose the right plan for your team.
        </h6>
      </div>
      <main>
        {/* Toggle Section */}
        <section className="toggle-section">
          <p>Pay Monthly</p>
          <div
            onClick={() => togglePricingButton(!monthly)}
            className={monthly ? "toggle-area anually" : "toggle-area monthly"}
          >
            <div
              className="toggle-btn"
              onClick={() => togglePricingButton(!monthly)}
            />
          </div>

          <p>Pay Yearly</p>
        </section>
        <br />
        <br />
        <br />
        <section className="pricing-container">
          {/* Container 1 */}
          <article className="pricing container-1">
            <h2>Free</h2>
            <span>All the essentials you need to get started</span>
            <p className="price-1">
              <p className="price-1 originalPrice">
                {" "}
                <span className="dollar-sign">$</span>0
              </p>
            </p>
            <span>Per month billed Yearly</span>
            <hr />
            <ul>
              <li>
                <p>✔️</p> 1 Member
              </li>
              <li>
                <p>✔️</p> Unlimited public and 0 private quizzes
              </li>
              <li>
                <p>✔️</p> Basic templates{" "}
              </li>
              <li>
                {" "}
                <p>✔️</p> All essential feature{" "}
              </li>
            </ul>
            <button>Select</button>
          </article>
          {/* Container 2 */}
          <article className="pricing container-2">
            <h3 id="home_h3">Most Popular</h3>
            <h2>Premium</h2>
            <span>All the essentials you need to get started</span>
            <p className="price-2">
              <p className="price-2 originalPrice">
                {" "}
                <span className="dollar-sign">$</span>
                {monthly ? "24" : "177"}
              </p>
            </p>
            <span>Per month billed Yearly</span>
            <hr style={{ borderBottom: "2px solid gray" }} />
            <ul>
              <li>
                <p>✔️</p> 3 Members
              </li>
              <li>
                <p>✔️</p> Unlimited public and limited private quizzes
              </li>
              <li>
                <p>✔️</p> All templates{" "}
              </li>
              <li>
                {" "}
                <p>✔️</p> All essential feature{" "}
              </li>
            </ul>
            <button>Learn more</button>
          </article>
          {/* Container 3 */}
          <article className="pricing container-3">
            <h2 style={{ paddingBottom: "13px" }}>Premium Pro</h2>
            <span>All the essentials you need to get started</span>
            <p className="price-3">
              <p className="price-3 originalPrice">
                {" "}
                <span className="dollar-sign">$</span>
                {monthly ? "39" : "277"}
              </p>
            </p>
            <span>Per month billed Yearly</span>
            <hr />
            <ul>
              <li>
                <p>✔️</p> 7 Members
              </li>
              <li>
                <p>✔️</p> Unlimited public and Unlimited private quizzes
              </li>
              <li>
                <p>✔️</p> All templates{" "}
              </li>
              <li>
                {" "}
                <p>✔️</p> All essential feature{" "}
              </li>
            </ul>
            <button>Learn more</button>
          </article>
        </section>
      </main>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

const questions = [
  {
    id: 1,
    question: "What does Quiz Secure do?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed. eiusmod tempor incididunt ut labore et dolore magna aliqua.Elementum sagittis vitae et leo duis ut. Ut tortor pretium",
  },
  {
    id: 2,
    question: "What is the Proctoring feature?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed. eiusmod tempor incididunt ut labore et dolore magna aliqua.Elementum sagittis vitae et leo duis ut. Ut tortor pretium",
  },
  {
    id: 3,
    question: "Can I use Quiz Secure to test candidates before hiring them?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed. eiusmod tempor incididunt ut labore et dolore magna aliqua.Elementum sagittis vitae et leo duis ut. Ut tortor pretium",
  },
  {
    id: 4,
    question:
      "Can Quiz Secure Help me evaluate compliance knowledge and skills?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed. eiusmod tempor incididunt ut labore et dolore magna aliqua.Elementum sagittis vitae et leo duis ut. Ut tortor pretium",
  },
  {
    id: 5,
    question:
      "How does Quiz Secure ensures and maintains the security of Quizzes?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed. eiusmod tempor incididunt ut labore et dolore magna aliqua.Elementum sagittis vitae et leo duis ut. Ut tortor pretium",
  },
];

const FAQ = () => {
  const [data, setData] = useState(questions);
  const [expandedId, setExpandedId] = useState(-1);

  const toggleFaq = (id) => {
    setExpandedId(id);

    // when question faq is clicked second time...
    if (id === expandedId) setExpandedId(-1);
  };

  return (
    <div className="Faq_div">
      <div className="container-faq">
        <div className="container-fluid Main_page_quotes">
          <h6 className="Text-center">Frequently Asked Questions</h6>
          <br />
          <br />
          <br />
        </div>
        {data.map((curElem, i) => {
          return (
            <div className="Expand" key={i}>
              <div className="Expand-item">
                <button
                  aria-expanded={curElem.id === expandedId}
                  onClick={() => toggleFaq(curElem.id)}
                >
                  <span className="Expand-title">{curElem.question}</span>
                  <span className="icon" aria-hidden="true" />
                </button>
                <div className="Expand-content">
                  <p>{curElem.answer}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};
