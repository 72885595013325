import QuizApi from "../../services/QuizApi";
import "../../assets/styles/Settings.css";
import { useParams } from "react-router-dom";
import { MainNavbar } from "../Common/Navbar";
import React, { useEffect, useState } from "react";
import Sidebar from "../Common/Sidebar";
import { handleQuestionSubmit } from "../../utils/helpers";
import ContentEditable from "react-contenteditable";
import { getHTMLtoText } from "../../utils/generalHelp";

function ScoringOption() {
  const { quizId } = useParams();
  const [settings, setSettings] = useState(null);
  const links = [
    { to: `/general-settings/${quizId}`, text: "General Settings" },
    { to: `/scoring-option/${quizId}`, text: "Scoring Options" },
    { to: `/quiz-tracking/${quizId}`, text: "Quiz Tracking" },
    { to: `/security-settings/${quizId}`, text: "Browser Restrictions" },
    {
      to: `/smart-proctoring-system/${quizId}`,
      text: "Proctoring and Security",
    },
    { to: `/lead-integration/${quizId}`, text: "Lead Integration" },
  ];

  const [quizQuestions, setQuizQuestions] = useState([]);
  const [quizTitle, setQuizTitle] = useState("");

  useEffect(() => {
    // Fetch quiz settings using the QuizApi instance
    try {
      QuizApi.get(`/scoringoption/${quizId}/`).then((response) => {
        setSettings(response.data);
      });
    } catch (error) {
      console.error("Error getting quiz settings:", error);
    }
  }, [quizId]);

  useEffect(() => {
    QuizApi.get(`/quiz/${quizId}`)
      .then((resp) => {
        setQuizQuestions(resp?.data?.questions);
        setQuizTitle(resp?.data?.quiztitle);
      })
      .then((error) => {
        console.log();
      });
  }, []);

  const handleSelectChange = (event, key) => {
    const { value, type, checked } = event.target;
    if (type === "checkbox") {
      setSettings({
        ...settings,
        [key]: checked ? 1 : 0,
      });
    } else {
      setSettings({
        ...settings,
        [key]: value,
      });
    }

    QuizApi.patch(`/scoringoption/${quizId}/`, {
      [key]: type === "checkbox" ? (checked ? 1 : 0) : value,
    })
      .then((response) => {})
      .catch((error) => {
        console.error(`Error updating ${key} in the backend: ${error}`);
      });
  };

  const handleMandatoryChange = (e) => {
    handleQuestionSubmit(parseInt(e.target.value), {
      is_mandatory: e.target.checked,
    });

    let data = [...quizQuestions];
    for (let i = 0; i < data.length; i++) {
      let question = data[i];
      if (question.id === parseInt(e.target.value)) {
        question.is_mandatory = e.target.checked;
      }
    }
    setQuizQuestions([...data]);
  };
  return (
    <div>
      {settings ? (
        <div>
          <MainNavbar />
          <Sidebar quiztitle={quizTitle} links={links} />
          <div className="containermain quizsettings">
            <br></br>
            <div className="scoringsettingsoptions" id="scoring_options">
              <div>
                <p>Allow Question to be Skipped</p>
              </div>
              <div>
                <input
                  type="checkbox"
                  className="enterkeyevent"
                  name="allowskip"
                  id="allowskip"
                  onChange={(e) => {
                    handleSelectChange(e, "allowskip");
                  }}
                  checked={settings.allowskip}
                ></input>
              </div>
            </div>
            {settings.allowskip == 1 && (
              <div className="scoringsettingsoptions" id="scoring_options">
                <div>
                  <p>Mandatory Questions</p>
                  Select questions that cannot be skipped by quiz takers{" "}
                  <br></br>and must be answered to complete the quiz.
                </div>
                <div>
                  <input
                    type="checkbox"
                    className="enterkeyevent"
                    name="mandatoryquestion"
                    id="mandatoryquestion"
                    onChange={(e) => handleSelectChange(e, "mandatoryquestion")}
                    checked={settings.mandatoryquestion}
                  ></input>
                </div>
                <br></br>
              </div>
            )}
            <div className="scoringsettingsoptions" id="scoring_options">
              <div>
                <p>Negative Points</p>
                Negative marking deducts points from the total score <br></br>
                when a question is answered incorrectly.
              </div>
              <div>
                <input
                  type="checkbox"
                  className="enterkeyevent"
                  name="negativepoints"
                  id="negativepoints"
                  onChange={(e) => handleSelectChange(e, "negativepoints")}
                  checked={settings.negativepoints}
                ></input>
              </div>
            </div>
            <div className="scoringsettingsoptions" id="scoring_options">
              <div>
                <p>Assign Total Points to the Quiz:</p>
              </div>
              <div>
                <input
                  type="number"
                  className="enterkeyevent"
                  id="totalpoints"
                  readOnly={true}
                  defaultValue="100"
                  disabled=""
                ></input>
                &nbsp; points
              </div>
            </div>
            <div className="scoringsettingsoptions" id="scoring_options">
              <div>
                {" "}
                <p>Minimum Passing Score:</p>
              </div>
              <div>
                <input
                  type="number"
                  className="enterkeyevent"
                  id="minimumpassing"
                  onChange={(e) => handleSelectChange(e, "minimumpassing")}
                  onBlur={(e) => handleSelectChange(e, "minimumpassing")}
                  onKeyUp={(e) => handleSelectChange(e, "minimumpassing")}
                  value={settings.minimumpassing}
                  disabled=""
                ></input>{" "}
                &nbsp; %
              </div>
            </div>
            <br></br>
            <div className="scoringsettingsoptions" id="scoring_options">
              <div>
                {" "}
                <p>Assign Points to Each Question:</p>
              </div>
            </div>
            <br></br>
            <table className="assignpointstable" id="assignpointstable">
              <thead>
                <tr>
                  {settings.mandatoryquestion ? (
                    <th>
                      Mandatory<br></br>Questions
                    </th>
                  ) : (
                    ""
                  )}
                  <th style={{ borderRight: "none" }}></th>
                  <th>Questions</th>
                  <th>Points</th>
                  {settings.negativepoints ? <th>Negative Points</th> : ""}
                </tr>
              </thead>

              <tbody>
                {quizQuestions.map((question, index) => (
                  <tr key={index}>
                    {settings.mandatoryquestion ? (
                      <td className="text-center">
                        <input
                          type="checkbox"
                          className="enterkeyevent"
                          id="totalpoints"
                          value={question.id}
                          checked={question.is_mandatory}
                          onChange={handleMandatoryChange}
                        ></input>
                      </td>
                    ) : (
                      ""
                    )}

                    <td>
                      <span style={{ whiteSpace: "nowrap" }}>
                        {" "}
                        Q: {index + 1}{" "}
                      </span>
                    </td>

                    <td>
                      <ContentEditable
                        tagName="pre"
                        html={`${question?.question_title}`}
                        disabled={true}
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="number"
                        className="enterkeyevent"
                        id="totalpoints"
                        defaultValue={parseInt(100 / quizQuestions.length)}
                        disabled=""
                      ></input>
                    </td>
                    {settings?.negativepoints ? (
                      <td className="text-center">
                        <input
                          type="number"
                          className="enterkeyevent"
                          id="totalpoints"
                          defaultValue={parseInt(100 / quizQuestions.length)}
                          disabled=""
                        ></input>
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>
      ) : (
        <p>Loading settings...</p>
      )}
    </div>
  );
}

export default ScoringOption;
