import React, { useState } from "react";
import "../../assets/styles/components/addQuestionPopup.css";
import { BiSolidLock } from "react-icons/bi";
import DataTemplateQuestionType from "../../utils/questionTypeStruture";
import QuizApi from "../../services/QuizApi";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger } from "react-bootstrap";
import QuestionTypeTooltipText from "../../utils/questionTypeTooltip";
import { useParams } from "react-router-dom";

export default function AddQuestionPopup(props) {
  const dispatch = useDispatch();
  const firstQuestionId = useSelector((state) => state.firstQuestionId);
  const { quizQuestionId, setFirstQuestionId, setQuizQuestions } =
    bindActionCreators(actionCreators, dispatch);
  const quizQuestions = useSelector((state) => state.quizQuestions);
  const { quizId } = useParams();
  const [lastSelectedQuestion, setLastSelectedQuestion] = useState(
    localStorage.getItem("last_selected_question") &&
      JSON.parse(localStorage.getItem("last_selected_question"))[quizId],
  );

  const question_type_list = [
    { type: "Multiple Choice Question", isLock: false },
    { type: "True or False Question", isLock: false },
    { type: "Essay Question", isLock: false },
    { type: "Multiple Response Question", isLock: false },
    { type: "Yes or No Question", isLock: false },
    { type: "Upload a File", isLock: true },
    { type: "Rating Scale", isLock: true },
    { type: "Ranking Question", isLock: true },
    { type: "Matrix Question", isLock: true },
    { type: "Video Response", isLock: true },
  ];

  const createNewQuestion = async (type) => {
    let formData = new FormData();
    const question_body = DataTemplateQuestionType[type];

    for (let key in question_body) {
      if (key === "correct_answers" || key === "options") {
        formData.append(key, JSON.stringify(question_body[key]));
      } else if (key === "quiz") {
        formData.append(key, quizId);
      } else if (key === "question_title") {
        formData.append(key, "");
      } else {
        formData.append(key, question_body[key]);
      }
      if (key === "question_order") {
        formData.append(key, quizQuestions.length + 1);
      }
    }
    try {
      const response = await QuizApi.post(`questions/`, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      quizQuestionId(response.data.id);
      setTimeout(() => {
        const main_sidebar = document.getElementById("questions-container");
        main_sidebar.scrollTo(0, main_sidebar.scrollHeight * 2);
      }, 1);
      if (firstQuestionId === 0) {
        setFirstQuestionId(response.data.id);
        setQuizQuestions([response.data]);
        return 0;
      }
      setQuizQuestions([...quizQuestions, response.data]);
      props?.setIsQuestionPopupOpen(false);
    } catch (error) {
      console.log(error, "iserror");
    }
  };

  const handleItemClick = (item) => {
    createNewQuestion(item);
    setLastSelectedQuestion(
      localStorage.setItem(
        "last_selected_question",
        JSON.stringify({ [quizId]: item }),
      ),
    );
  };

  return (
    <div className="main-add-q-popup">
      <ul className="question-list">
        <p className="question-list-header">Select a Question Type</p>
        {question_type_list.map((item, index) => (
          <OverlayTrigger
            placement="right"
            overlay={QuestionTypeTooltipText[item.type]}
            key={index}
          >
            <span
              className={
                lastSelectedQuestion === item.type
                  ? "last-selected-question"
                  : "list-item-parent"
              }
            >
              <li
                className={item.isLock ? "list-item is-lock" : "list-item"}
                onClick={() => {
                  handleItemClick(item.type);
                }}
              >
                {" "}
                {item.type}
                {item.isLock && <BiSolidLock className="lock-icon" />}
              </li>
            </span>
          </OverlayTrigger>
        ))}
      </ul>
    </div>
  );
}
