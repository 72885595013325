import { useDispatch, useSelector } from "react-redux";
import React, { useRef, useState } from "react";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../state";
import "../../../assets/styles/index.css";
import { handleTakeNextQuestion } from "../../../utils/helpers";
import { BACKEND_URL } from "../../../services/QuizApi";
import { useEffect } from "react";

export default function MultipleChoiceQuestion() {
  const [choice, setChoice] = useState("");
  const [isFirst, setIsFirst] = useState(true);
  const dispatch = useDispatch();
  const { setQuizProgress } = bindActionCreators(actionCreators, dispatch);
  const quizProgress = useSelector((state) => state.quizProgress);

  const takeQuizQuestion = useSelector((state) => state.takeQuizQuestion);
  const quizGeneralSettings = useSelector((state) => state.quizGeneralSettings);
  const { setTakeQuizQuestion, setCompleteQuestion } = bindActionCreators(
    actionCreators,
    dispatch,
  );
  const completeQuestion = useSelector((state) => state.completeQuestion);
  const isTimeOutForceSubmit = useSelector(
    (state) => state.isTimeOutForceSubmit,
  );
  const [options, setOptions] = useState({});
  let submitButtonRef = useRef(null);

  const handleChange = (event) => {
    setChoice(event.target.value);
    if (isFirst) {
      setQuizProgress(
        (completeQuestion / quizGeneralSettings.selectnumberofquestion) * 100,
      );
      sessionStorage.setItem(
        "quizProgress",
        (completeQuestion / quizGeneralSettings.selectnumberofquestion) * 100,
      );

      // setCompleteQuestion(completeQuestion + 1);
      setIsFirst(false);
    }
  };

  const convertHtmlToText = (text) => {
    const ele = document.createElement("div");
    ele.innerHTML = text;
    return ele.innerText;
  };

  const handleSubmit = (event) => {
    handleTakeNextQuestion(takeQuizQuestion["question_id"], [choice]);
    setIsFirst(true);
    event.preventDefault();
  };

  useEffect(() => {
    const preventTextSelection = (event) => {
      if (
        event.target instanceof HTMLInputElement ||
        event.target instanceof HTMLTextAreaElement ||
        event.target instanceof HTMLDivElement
      ) {
        return;
      }
      event.preventDefault(); // Prevent text selection for other elements
    };

    document.addEventListener("mousedown", preventTextSelection);
    document.addEventListener("selectstart", preventTextSelection);

    // Cleanup listeners when the component unmounts
    return () => {
      document.removeEventListener("mousedown", preventTextSelection);
      document.removeEventListener("selectstart", preventTextSelection);
    };
  }, []);

  useEffect(() => {
    if (isTimeOutForceSubmit) {
      submitButtonRef?.current?.click();
    }
  }, [isTimeOutForceSubmit]);

  const shuffleObj = (sourceArray) => {
    if (
      quizGeneralSettings?.randomorder === "shuffle answers" ||
      quizGeneralSettings?.randomorder === "shuffle questions and answers"
    ) {
      let new_obj = {};
      while (true) {
        let random_number = Math.floor(
          Math.random() * (Object.keys(sourceArray).length - 1),
        );
        new_obj[Object.keys(sourceArray)[random_number]] =
          sourceArray[Object.keys(sourceArray)[random_number]];
        if (
          Object.keys(sourceArray).length - 1 ===
          Object.keys(new_obj).length
        ) {
          break;
        }
      }
      new_obj = { ...new_obj, ...sourceArray };
      setOptions(new_obj);
      return new_obj;
    }
    setOptions(sourceArray);
    return sourceArray;
  };

  useEffect(() => {
    shuffleObj(takeQuizQuestion["options"]);
  }, []);

  return (
    <div className="quizcontainer">
      <div className="quizcontainer">
        <div className="take-common-questions">
          <div
            style={{ userSelect: "none" }}
            className="question-title"
            dangerouslySetInnerHTML={{
              __html: takeQuizQuestion["question_title"],
            }}
          ></div>
          {takeQuizQuestion?.question_image && (
            <div className="between-img">
              <img
                src={BACKEND_URL + "media/" + takeQuizQuestion?.question_image}
                alt=""
              />
            </div>
          )}

          <div className="options-div">
            {Object.entries(options).map(([key, value], index) => (
              <div className="options" key={index}>
                <input
                  className="choice"
                  type="radio"
                  name="choice"
                  id={key}
                  value={key}
                  onChange={handleChange}
                />
                <label
                  style={{ userSelect: "none" }}
                  htmlFor={key}
                  className="otp-number"
                >
                  {String.fromCharCode(65 + index)}
                </label>
                <label
                  style={{ userSelect: "none" }}
                  htmlFor={key}
                  className="option"
                  dangerouslySetInnerHTML={{ __html: value }}
                ></label>
              </div>
            ))}
          </div>
          {takeQuizQuestion["show_explanation"] && (
            <div className="question-explanation">
              <div className="explanation-title">Explanation: </div>
              <div className="explanation-body">
                {convertHtmlToText(takeQuizQuestion["explanation_text"])}
              </div>
            </div>
          )}
          <button
            className={isFirst ? "btn-submit btn-disable" : "btn-submit"}
            ref={submitButtonRef}
            onClick={handleSubmit}
          >
            {quizGeneralSettings.selectnumberofquestion === completeQuestion
              ? "Submit"
              : "Next Question"}
          </button>
        </div>
      </div>
    </div>
  );
}
