const getLocalData = () => {
  if (document.URL.includes("preview")) {
    let local_data = sessionStorage.getItem("quizGeneralSettings");
    if (local_data) {
      try {
        local_data = JSON.parse(local_data);
        return local_data;
      } catch (error) {
        return {};
      }
    } else {
      return {};
    }
  } else {
    return {};
  }
};

const reducer = (state = getLocalData(), action) => {
  if (action.type === "quizGeneralSettings") {
    return action.payload;
  } else {
    return state;
  }
};

export default reducer;
