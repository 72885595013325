const getBeforeReloadQuestion = () => {
  if (localStorage.getItem("before_reload_question") === "false") {
    return false;
  }
  if (localStorage.getItem("before_reload_question")) {
    return parseInt(localStorage.getItem("before_reload_question"));
  }
  return false;
};

const reducer = (state = getBeforeReloadQuestion(), action) => {
  if (action.type === "setQuestionId") {
    // state = action.payload;
    return action.payload;
  } else {
    return state;
  }
};

export default reducer;
