import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/styles/takeQuiz/endQuiz.css";
import QuizApi from "../../services/QuizApi";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useNavigate } from "react-router-dom";
import Loading from "./common/Loading";
import {
  useFullscreenDialogOpen,
  setIsQuizSubmitted,
  setIsFirtRender,
} from "../../contexts/FullscreenSubmitted";

export default function EndQuiz(props) {
  const takeQuizQuestion = useSelector((state) => state.takeQuizQuestion);
  const dispatch = useDispatch();
  const {
    setTakeQuizQuestion,
    setIsTimeOutForceSubmit,
    setQuizGeneralSettings,
    setQuizBrowserSettings,
    setQuizResult,
    setCompleteQuestion,
    setQuizProgress,
  } = bindActionCreators(actionCreators, dispatch);
  let navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(true);
  const [isInstructionShown, setIsInstructionShown] = useState(false);
  const firstQuestion = useRef(null);
  const isBrowserSupportFullScreen = useRef(false);
  const isFullscreenEnabled = useRef(false);

  const setIsFullscreenDialogOpen = useFullscreenDialogOpen(
    (state) => state.setIsFullscreenDialogOpen,
  );

  useEffect(() => {
    const disableInspect = (e) => {
      // Disable right-click context menu
      if (e.type === "contextmenu") {
        e.preventDefault();
      }

      // Disable F12, F11, Ctrl+Shift+I, Ctrl+Shift+J, Ctrl+U, Alt+Tab, Alt+Ctrl+Tab
      if (e.type === "keydown") {
        if (
          e.key === "F12" || // F12
          e.key === "F11" || // F11
          (e.ctrlKey && e.shiftKey && e.key === "I") || // Ctrl+Shift+I
          (e.ctrlKey && e.shiftKey && e.key === "J") || // Ctrl+Shift+J
          (e.ctrlKey && e.key === "U") || // Ctrl+U
          (e.altKey && e.key === "Tab") || // Alt+Tab
          (e.altKey && e.ctrlKey && e.key === "Tab") // Alt+Ctrl+Tab
        ) {
          e.preventDefault();
        }
      }
    };

    // Add event listeners
    document.addEventListener("contextmenu", disableInspect);
    document.addEventListener("keydown", disableInspect);

    // Cleanup function to remove event listeners when component unmounts
    return () => {
      document.removeEventListener("contextmenu", disableInspect);
      document.removeEventListener("keydown", disableInspect);
    };
  }, []);

  useEffect(() => {
    const exitFullscreen = () => {
      if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      ) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    };

    exitFullscreen();
  }, []);

  const checkFullScreenSupport = () => {
    return (
      document.fullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.webkitFullscreenEnabled ||
      document.msFullscreenEnabled
    );
  };

  const handleNextQuiz = (e) => {
    setIsLoaded(true);
    sessionStorage.setItem("quizStartTime", new Date().getTime());
    QuizApi.post(`quiz-initiate/`, {
      ...takeQuizQuestion?.user_data,
      quizid: takeQuizQuestion?.next_quiz_id,
    })
      .then((response) => {
        setQuizGeneralSettings(response.data?.general_settings);
        setQuizBrowserSettings(response.data?.browser_settings);
        setQuizResult(response.data?.result_data);
        setCompleteQuestion(1);
        setQuizProgress(0);
        setIsTimeOutForceSubmit(false);
        sessionStorage.setItem(
          "takeQuizQuestion",
          JSON.stringify(response.data?.first_question),
        );
        sessionStorage.setItem(
          "quizGeneralSettings",
          JSON.stringify(response.data?.general_settings),
        );
        sessionStorage.setItem(
          "quizBrowserSettings",
          JSON.stringify(response.data?.browser_settings),
        );
        sessionStorage.setItem(
          "quizResult",
          JSON.stringify(response.data?.result_data),
        );
        sessionStorage.setItem("completeQuestions", 1);
        sessionStorage.setItem("min", response.data?.general_settings?.minutes);
        sessionStorage.setItem("sec", 0);
        sessionStorage.setItem("quizProgress", 0);
        props?.setIsTabChanging(false);
        props?.setIsMouseLeaveOn(false);
        setIsFullscreenDialogOpen(false);
        setIsFirtRender(true);
        setIsQuizSubmitted(false);

        if (takeQuizQuestion?.transition === "automatic") {
          setTakeQuizQuestion(response.data?.first_question);
          navigate(
            `/preview/${takeQuizQuestion?.next_quiz_title
              .replace(/\s+/g, "-")
              .replaceAll("/", "-")}`,
          );
          if (response.data?.browser_settings?.switchingbrowsertabs) {
            document.documentElement.addEventListener(
              "mouseleave",
              props.customMouseLeaveEvent,
            );
            document.addEventListener("fullscreenchange", props?.handleFullscreenChange);
            document.addEventListener(
              "webkitfullscreenchange",
              props?.handleFullscreenChange,
            );
            document.addEventListener("mozfullscreenchange", props?.handleFullscreenChange);
            document.addEventListener("MSFullscreenChange", props?.handleFullscreenChange);
            const elem = document.documentElement;
            if (elem.requestFullscreen) {
              elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) {
              // Firefox
              elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
              // Chrome, Safari and Opera
              elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
              // IE/Edge
              elem.msRequestFullscreen();
            }
            props?.setIsMouseLeaveOn(true);
          } else {
            props?.setIsMouseLeaveOn(false);
            if (
              document.fullscreenElement ||
              document.mozFullScreenElement ||
              document.webkitFullscreenElement ||
              document.msFullscreenElement
            ) {
              if (document.exitFullscreen) {
                document.exitFullscreen();
              } else if (document.mozCancelFullScreen) {
                // Firefox
                document.mozCancelFullScreen();
              } else if (document.webkitExitFullscreen) {
                // Chrome, Safari and Opera
                document.webkitExitFullscreen();
              } else if (document.msExitFullscreen) {
                // IE/Edge
                document.msExitFullscreen();
              }
            }
          }
        } else {
          if (response.data?.browser_settings?.switchingbrowsertabs) {
            setIsInstructionShown(true);
            setTakeQuizQuestion(response.data?.first_question);
            firstQuestion.current = response.data?.first_question;
            props?.setIsMouseLeaveOn(true);
            props?.setIsTabChanging(false);
            document.documentElement.addEventListener(
              "mouseleave",
              props.customMouseLeaveEvent,
            );
            document.addEventListener("fullscreenchange", props?.handleFullscreenChange);
            document.addEventListener(
              "webkitfullscreenchange",
              props?.handleFullscreenChange,
            );
            document.addEventListener("mozfullscreenchange", props?.handleFullscreenChange);
            document.addEventListener("MSFullscreenChange", props?.handleFullscreenChange);

            const elem = document.documentElement;
            if (elem.requestFullscreen) {
              elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) {
              // Firefox
              elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
              // Chrome, Safari and Opera
              elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
              // IE/Edge
              elem.msRequestFullscreen();
            }

            navigate(
              `/preview/${takeQuizQuestion?.next_quiz_title
                .replace(/\s+/g, "-")
                .replaceAll("/", "-")}`,
            );
          } else {
            if (
              document.fullscreenElement ||
              document.mozFullScreenElement ||
              document.webkitFullscreenElement ||
              document.msFullscreenElement
            ) {
              if (document.exitFullscreen) {
                document.exitFullscreen();
              } else if (document.mozCancelFullScreen) {
                // Firefox
                document.mozCancelFullScreen();
              } else if (document.webkitExitFullscreen) {
                // Chrome, Safari and Opera
                document.webkitExitFullscreen();
              } else if (document.msExitFullscreen) {
                // IE/Edge
                document.msExitFullscreen();
              }
            }
            setTakeQuizQuestion(response.data?.first_question);
            navigate(
              `/preview/${takeQuizQuestion?.next_quiz_title
                .replace(/\s+/g, "-")
                .replaceAll("/", "-")}`,
            );
            props?.setIsMouseLeaveOn(false);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    isBrowserSupportFullScreen.current = checkFullScreenSupport();
    isFullscreenEnabled.current =
      takeQuizQuestion?.browser_settings?.switchingbrowsertabs;

    if (takeQuizQuestion?.show_next_quiz) {
      if (takeQuizQuestion?.transition === "automatic") {
        handleNextQuiz();
      }
    }
    setIsLoaded(false);
  }, []);

  return (
    <>
      {isLoaded ? (
        <Loading />
      ) : (
        <>
          {" "}
          {isFullscreenEnabled.current &&
            !isBrowserSupportFullScreen.current && (
              <p className="fullscreen_notsupport_message">
                Your browser does not support full-screen mode for this quiz.
                Please use one of the supported browsers: Chrome, Firefox, or
                Microsoft Edge.
              </p>
            )}
          <div className="main-end-quiz">
            {takeQuizQuestion?.showcustommsg && (
              <div className="custom-message">
                <pre>{takeQuizQuestion?.custommessage}</pre>
              </div>
            )}
            {takeQuizQuestion?.showpassfail && (
              <div className="pass-fail">
                {takeQuizQuestion?.pass_fail_message}
              </div>
            )}
            {takeQuizQuestion?.showscore && (
              <div className="show-score">
                {takeQuizQuestion?.scorecustommessage}
              </div>
            )}

            {takeQuizQuestion?.show_next_quiz && (
              <>
                <div className="head-next-quiz">
                  You may now take the next quiz.
                </div>
                <div className="next-quiz">
                  <div className="next-quiz-title">
                    {takeQuizQuestion?.next_quiz_title}
                  </div>
                  <hr />
                  <div className="info-table">
                    <table>
                      <tbody>
                        <tr>
                          <td>Total questions:</td>
                          <td>{takeQuizQuestion?.next_quiz_total_questions}</td>
                        </tr>
                        <tr>
                          <td>Time limit:</td>
                          <td>{takeQuizQuestion?.next_quiz_time}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <button
                    className="start-next-quiz"
                    disabled={
                      isFullscreenEnabled.current &&
                      !isBrowserSupportFullScreen.current
                    }
                    onClick={() => {
                      takeQuizQuestion?.browser_settings?.switchingbrowsertabs
                        ? setIsInstructionShown(true)
                        : handleNextQuiz();
                    }}
                  >
                    {takeQuizQuestion?.takenextquizbutton}
                  </button>
                </div>
              </>
            )}

            {isInstructionShown && (
              <div className="quizEndPage">
                <div className="instructions">
                  <div className="child-instruct">
                    <h1>Important: Please Read Before Starting</h1>
                    <div className="instruct">
                      <p>
                        The quiz will open in full screen mode to help you
                        focus. If you exit full screen mode, switch tabs, or
                        close the quiz window before completing the quiz, your
                        progress will be submitted, and you will not be able to
                        return.
                      </p>
                      <br />
                      <p>
                        {" "}
                        If the quiz has a timer, it will end and submit your
                        answers when time runs out.
                      </p>
                      <br />
                      <p>
                        Make sure you have a stable internet connection and are
                        ready to begin.
                      </p>
                      <br />
                    </div>
                    <button
                      className="start-quiz-btn"
                      onClick={() => {
                        handleNextQuiz();
                      }}
                    >
                      Start the Quiz
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
