import QuizApi from "../../services/QuizApi";
import "../../assets/styles/Settings.css";
import { Link, useParams } from "react-router-dom";
import { MainNavbar } from "../Common/Navbar";
import React, { useEffect, useState } from "react";
import Sidebar from "../Common/Sidebar";

function SmartProctoring() {
  const { quizId } = useParams();
  const [settings, setSettings] = useState(null);
  const links = [
    { to: `/general-settings/${quizId}`, text: "General Settings" },
    { to: `/scoring-option/${quizId}`, text: "Scoring Options" },
    { to: `/quiz-tracking/${quizId}`, text: "Quiz Tracking" },
    { to: `/security-settings/${quizId}`, text: "Browser Restrictions" },
    {
      to: `/smart-proctoring-system/${quizId}`,
      text: "Proctoring and Security",
    },
    { to: `/lead-integration/${quizId}`, text: "Lead Integration" },
  ];

  useEffect(() => {
    // Fetch quiz settings using the QuizApi instance
    try {
      QuizApi.get(`/quiztracking/${quizId}/`).then((response) => {
        setSettings(response.data);
      });
    } catch (error) {
      console.error("Error getting quiz settings:", error);
    }
  }, [quizId]);

  return (
    <div>
      {settings ? (
        <div>
          <MainNavbar />
          <Sidebar quiztitle="Your Quiz Title" links={links} />
          <div className="containermain quizsettings">
            <br></br>
            <div className="securitysettingsoptions" style={{ width: "100%" }}>
              Use our advanced Al proctoring technology to continuously monitor
              suspicious activity while taking quiz. <br></br>
              <br></br>
              Select the activity you would like to be alerted about:
            </div>
            <br></br>
            <div className="securitysettingsoptions">
              <input type="checkbox" name="" id=""></input>
              <p>Quiz taker not present</p>
              Quiz taker leaves their desk.
            </div>
            <br></br>
            <div className="securitysettingsoptions">
              <input type="checkbox" name="" id=""></input>
              <p>Presence of a stranger</p>
              Another person is detected in the room.
            </div>
            <br></br>
            <div className="securitysettingsoptions">
              <input type="checkbox" name="" id=""></input>
              <p>Active window change</p>
              The active desktop screen window suddenly changes<br></br> away
              from the quiz.
            </div>
            <br></br>
            <div className="securitysettingsoptions">
              <input type="checkbox" name="" id=""></input>
              <p>Gaze detection</p>
              Quiz taker looks away from the screen for<br></br> more than 5
              seconds.
            </div>
            <br></br>
          </div>
        </div>
      ) : (
        <p>Loading settings...</p>
      )}
    </div>
  );
}

export default SmartProctoring;
