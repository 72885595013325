const getLocalData = () => {
  if (document.URL.includes("preview")) {
    let local_data = sessionStorage.getItem("takeQuizQuestion");
    if (local_data) {
      try {
        return JSON.parse(local_data);
      } catch (error) {
        return {};
      }
    } else {
      return {};
    }
  } else {
    return {};
  }
};

const reducer = (state = getLocalData(), action) => {
  if (action.type === "takeQuizQuestion") {
    return action.payload;
  } else {
    return state;
  }
};

export default reducer;
