import React, { useEffect, useState } from "react";
import "../../../assets/styles/yesNo.css";
import ContentEditable from "react-contenteditable";
import { useDispatch, useSelector } from "react-redux";
import "../../../assets/styles/commonQuestions.css";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../state";
import {
  handleDeleteQuestion,
  handleQuestionImageUpload,
  handleQuestionSubmit,
} from "../../../utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { getHTMLtoText } from "../../../utils/generalHelp";

export default function YesNo(props) {
  const [propsValues, setPropsValues] = useState({ ...props.data });
  const setQuestions = useSelector((state) => state.setQuestions);
  const isExplanationOn = useSelector((state) => state.isExplanationOn);
  const imagePath = useSelector((state) => state.imagePath);

  const handleAnswerChange = (event) => {
    setPropsValues({ ...propsValues, correct_answers: [event.target.value] });
    handleQuestionSubmit(setQuestions, {
      correct_answers: [event.target.value],
    });
  };

  const handleExplanationBlur = (event) => {
    handleQuestionSubmit(setQuestions, {
      explanation_text: event.target.innerHTML,
    });
  };

  const removeExtraCss = (element) => {
    let elementTree = element.getElementsByTagName("*");
    let res = "";
    const needed_css_rules = ["fontStyle", "fontWight"];

    for (let i = 0; i < elementTree.length; i++) {
      let ele = elementTree[i];
      if (ele.tagName === "INPUT") {
        continue;
      }
      let style_need = {};
      Object.entries(ele.style).map(([key, value]) => {
        if (needed_css_rules.includes(key)) {
          style_need[key] = value;
        }
        return null;
      });
      ele.removeAttribute("style");
      ele.removeAttribute("class");
      Object.entries(style_need).map(([key, value]) => {
        ele.style[key] = value;
        return null;
      });
      res = res + ele;
    }

    return res;
  };

  const handleOnPaste = (event, from) => {
    if (event.clipboardData.types.includes("Files")) {
      event.preventDefault();
      return;
    }
    if (event.clipboardData.types.includes("text/html")) {
      event.preventDefault();
      let value = event.clipboardData.getData("text/html");
      let tempElement = document.createElement("p");
      tempElement.innerHTML = value;
      removeExtraCss(tempElement);
      switch (from) {
        case "question_title":
          setPropsValues({
            ...propsValues,
            question_title: `<pre>${tempElement.innerHTML.trim()}</pre>`,
          });
          break;
        case "explanation_text":
          setPropsValues({
            ...propsValues,
            explanation_text: `<pre>${tempElement.innerHTML.trim()}</pre>`,
          });
          break;
        case "options":
          setPropsValues({
            ...propsValues,
            options: {
              ...propsValues["options"],
              [event.currentTarget.id]:
                `<pre>${tempElement.innerHTML.trim()}</pre>`,
            },
          });
          break;
        default:
          break;
      }
    }
  };

  const handleQuestionTitleChange = (event) => {
    try {
      let element = document.getElementById(`question_id-${setQuestions}`);
      element.innerText = event.currentTarget.innerText
        .trim()
        .replace(/\n/g, " ");
      element.parentElement.title = event.currentTarget.innerText.trim();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="question-main-container">
        <div className="question-title">
          <div className="question-title-head">
            Yes or No Questions <span className="total-questions-of">1/10</span>{" "}
          </div>
          <ContentEditable
            onFocus={(event) =>
              window.getSelection().selectAllChildren(event.target)
            }
            disable={"false"}
            onBlur={(event) => {
              handleQuestionSubmit(setQuestions, {
                question_title: event.target.innerHTML,
              });
            }}
            className="question-title-body"
            html={propsValues["question_title"]}
            onChange={(event) => {
              setPropsValues({
                ...propsValues,
                question_title: event.currentTarget.innerHTML,
              });
              handleQuestionTitleChange(event);
            }}
            tagName="div"
            placeholder="Enter your Yes or No Question here.."
            onPaste={(event) => {
              handleOnPaste(event, "question_title");
            }}
          />
          <FontAwesomeIcon
            icon={faTrashCan}
            className="question-delete-icon"
            size="xl"
            onClick={() => {
              handleDeleteQuestion(setQuestions);
            }}
          />
        </div>
        {getHTMLtoText(propsValues["question_title"]) === "" && (
          <p className="question-title-required">
            Please enter a title for your question to proceed
          </p>
        )}

        {imagePath && (
          <div className="upload-img">
            <div className="upload-img-div">
              <button
                className="btn-remove-img"
                onClick={() => {
                  handleQuestionImageUpload({
                    image: "",
                    question_id: setQuestions,
                  });
                }}
              >
                Remove
              </button>
              <img className="question-image" src={imagePath} alt="" />
            </div>
          </div>
        )}
        <div className="question-choices">
          <div className="question-choices-head">Select Correct Answer</div>
          <div className="question-choices-body">
            {Object.entries(propsValues["options"]).map(
              ([key, value], index) => (
                <div className="choice" key={index}>
                  <span>
                    <input
                      type="radio"
                      onChange={handleAnswerChange}
                      name="choice"
                      className="btn-radio"
                      value={key}
                      checked={propsValues["correct_answers"].includes(key)}
                    />
                    <div className="input-ans">{value}</div>
                  </span>
                </div>
              ),
            )}
          </div>
        </div>
        {isExplanationOn && (
          <div className="explanation">
            <div className="explanation-head">
              Add Explanation for Answer (Optional)
            </div>
            <ContentEditable
              onFocus={(event) =>
                window.getSelection().selectAllChildren(event.target)
              }
              disable={"false"}
              onBlur={handleExplanationBlur}
              className="explanation-body"
              disabled={false}
              tagName="div"
              html={propsValues["explanation_text"]}
              onChange={(event) => {
                setPropsValues({
                  ...propsValues,
                  explanation_text: event.target.value,
                });
              }}
              placeholder="Type Explanation here..."
              onPaste={(event) => {
                handleOnPaste(event, "explanation_text");
              }}
            />
          </div>
        )}
      </div>
    </>
  );
}
