import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import ContentEditable from "react-contenteditable";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../state";
import {
  handleQuestionSubmit,
  handleDeleteQuestion,
  handleQuestionImageUpload,
} from "../../../utils/helpers";
import { getHTMLtoText } from "../../../utils/generalHelp";

export default function MultipleResponse(props) {
  const [propsValues, setPropsValues] = useState({ ...props.data });
  const isExplanationOn = useSelector((state) => state.isExplanationOn);
  const dispatch = useDispatch();
  const { setImagePath } = bindActionCreators(actionCreators, dispatch);
  const setQuestions = useSelector((state) => state.setQuestions);
  const imagePath = useSelector((state) => state.imagePath);
  const [optionMoreThanTwo, setOptionMoreThanTwo] = useState(false);

  const handleAnswerChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setPropsValues((prevValues) => ({
        ...prevValues,
        correct_answers: [...prevValues.correct_answers, value],
      }));
    } else {
      setPropsValues((prevValues) => ({
        ...prevValues,
        correct_answers: prevValues.correct_answers.filter(
          (answer) => answer !== value,
        ),
      }));
    }
  };

  const handleAnswerBlur = () => {
    handleQuestionSubmit(setQuestions, {
      options: { ...propsValues.options },
      correct_answers: [...propsValues.correct_answers],
    });
  };

  const handleOptionEdit = (event) => {
    setPropsValues((prevValues) => ({
      ...prevValues,
      options: {
        ...prevValues.options,
        [event.currentTarget.id]: event.target.value,
      },
    }));
  };

  const handleAddAdditionalOptions = () => {
    let currentOptionCount = Object.keys(propsValues.options).length + 1;
    while (
      Object.keys(propsValues.options).includes(`opt_${currentOptionCount}`)
    ) {
      currentOptionCount += 1;
    }
    setPropsValues((prevValues) => ({
      ...prevValues,
      options: { ...prevValues.options, [`opt_${currentOptionCount}`]: "" },
    }));
  };

  const removeOptions = (key) => {
    const updatedOptions = { ...propsValues.options };
    delete updatedOptions[key];
    setPropsValues((prevValues) => ({
      ...prevValues,
      options: updatedOptions,
    }));
    handleQuestionSubmit(setQuestions, {
      options: updatedOptions,
      correct_answers: [...propsValues.correct_answers],
    });
  };

  const handleOptionBlur = (event) => {
    const optKey = event.currentTarget.id;
    const updatedOptions = {
      ...propsValues.options,
      [optKey]: event.currentTarget.innerHTML,
    };
    handleQuestionSubmit(setQuestions, {
      options: updatedOptions,
    });
  };

  const handleExplanationBlur = (event) => {
    handleQuestionSubmit(setQuestions, {
      explanation_text: event.target.innerHTML,
    });
  };

  useEffect(() => {
    const optList = Object.values(propsValues.options);
    const filledCount = optList.reduce(
      (count, option) => (option.length > 0 ? count + 1 : count),
      0,
    );
    setOptionMoreThanTwo(filledCount > 1);
  }, [propsValues]);

  // Function to remove extra CSS from pasted content
  const removeExtraCss = (element) => {
    const elementTree = element.getElementsByTagName("*");

    for (let i = 0; i < elementTree.length; i++) {
      const ele = elementTree[i];
      if (ele.tagName === "INPUT") {
        continue;
      }
      ele.removeAttribute("style");
      ele.removeAttribute("class");
    }
  };

  // Updated handleOnPaste function to use removeExtraCss
  const handleOnPaste = (event, from) => {
    if (event.clipboardData.types.includes("Files")) {
      event.preventDefault();
      return;
    }
    if (event.clipboardData.types.includes("text/html")) {
      event.preventDefault();
      let value = event.clipboardData.getData("text/html");
      let tempElement = document.createElement("div");
      tempElement.innerHTML = value;
      removeExtraCss(tempElement);
      switch (from) {
        case "question_title":
          setPropsValues((prevValues) => ({
            ...prevValues,
            question_title: `<pre>${tempElement.innerHTML.trim()}</pre>`,
          }));
          break;
        case "explanation_text":
          setPropsValues((prevValues) => ({
            ...prevValues,
            explanation_text: `<pre>${tempElement.innerHTML.trim()}</pre>`,
          }));
          break;
        case "options":
          setPropsValues((prevValues) => ({
            ...prevValues,
            options: {
              ...prevValues.options,
              [event.currentTarget.id]:
                `<pre>${tempElement.innerHTML.trim()}</pre>`,
            },
          }));
          break;
        default:
          break;
      }
    }
  };

  const handleQuestionTitleChange = (event) => {
    try {
      let element = document.getElementById(`question_id-${setQuestions}`);
      element.innerText = event.currentTarget.innerText
        .trim()
        .replace(/\n/g, " ");
      element.parentElement.title = event.currentTarget.innerText.trim();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="question-main-container">
      <div className="question-title">
        <div className="question-title-head">
          Multiple Response Questions
          <span className="total-questions-of">1/10</span>
        </div>
        <ContentEditable
          onBlur={(event) => {
            handleQuestionSubmit(setQuestions, {
              question_title: event.target.innerHTML,
            });
          }}
          disable={false}
          className="question-title-body"
          html={`${propsValues.question_title ? "<pre>" : ""}${propsValues.question_title}${propsValues.question_title ? "</pre>" : ""}`}
          onChange={(event) => {
            setPropsValues((prevValues) => ({
              ...prevValues,
              question_title: event.target.value,
            }));
            handleQuestionTitleChange(event);
          }}
          tagName="div"
          placeholder="Enter your multiple response question here.."
          onPaste={(event) => {
            handleOnPaste(event, "question_title");
          }}
        />
        <FontAwesomeIcon
          icon={faTrashCan}
          className="question-delete-icon"
          size="xl"
          onClick={() => {
            handleDeleteQuestion(setQuestions);
          }}
        />
      </div>
      {getHTMLtoText(propsValues.question_title) === "" && (
        <p className="question-title-required">
          Please enter a title for your question to proceed
        </p>
      )}

      {imagePath && (
        <div className="upload-img">
          <div className="upload-img-div">
            <button
              className="btn-remove-img"
              onClick={() => {
                handleQuestionImageUpload({
                  image: "",
                  question_id: setQuestions,
                });
              }}
            >
              Remove
            </button>
            <img className="question-image" src={imagePath} alt="" />
          </div>
        </div>
      )}
      <div className="question-choices">
        <div className="question-choices-head">
          {optionMoreThanTwo ? "Select correct answer" : "Enter the answers"}
        </div>
        <div className="question-choices-body">
          {Object.entries(propsValues.options).map(([key, value], index) => (
            <div className="choice" key={key}>
              <span>
                <input
                  type="checkbox"
                  name="choice"
                  className="btn-radio"
                  onBlur={handleAnswerBlur}
                  value={key}
                  checked={propsValues.correct_answers.includes(key)}
                  onChange={handleAnswerChange}
                />
                <ContentEditable
                  onBlur={handleOptionBlur}
                  disable={false}
                  html={value}
                  placeholder={`Answer Number ${index + 1}`}
                  tagName="div"
                  className="input-ans"
                  id={key}
                  onChange={handleOptionEdit}
                  onPaste={(event) => {
                    handleOnPaste(event, "options");
                  }}
                />
              </span>
              {Object.keys(propsValues.options).length > 1 && (
                <FontAwesomeIcon
                  icon={faTrashCan}
                  size="lg"
                  className="trash"
                  style={{ color: "#002653" }}
                  onClick={() => {
                    removeOptions(key);
                  }}
                />
              )}
            </div>
          ))}
          <button className="btn-with-img" onClick={handleAddAdditionalOptions}>
            <FontAwesomeIcon icon={faPlus} size="lg" /> Add Additional Answer
            Choice
          </button>
        </div>
      </div>

      {isExplanationOn && (
        <div className="explanation">
          <div className="explanation-head">
            Add Explanation for Answer (Optional)
          </div>
          <ContentEditable
            disable={false}
            className="explanation-body"
            html={
              propsValues.explanation_text ? propsValues.explanation_text : ""
            }
            onChange={(event) => {
              setPropsValues((prevValues) => ({
                ...prevValues,
                explanation_text: event.target.value,
              }));
            }}
            tagName="div"
            placeholder="Enter explanation here..."
            onBlur={handleExplanationBlur}
            onPaste={(event) => {
              handleOnPaste(event, "explanation_text");
            }}
          />
        </div>
      )}
    </div>
  );
}
