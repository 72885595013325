import axios from "axios";

function getHeader() {
  let localStorageItem = localStorage.getItem("token");
  let header = {};
  header["Accept"] = "application/json";
  if (localStorageItem) {
    header["Authorization"] = "Token " + localStorageItem;
  }
  return header;
}

export default axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: getHeader(),
  // withCredentials: true,
});

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
