import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../state";
import { handleTakeNextQuestion } from "../../../utils/helpers";
import { BACKEND_URL } from "../../../services/QuizApi";

export default function MultipleResponseQuestion() {
  const [choice, setChoice] = useState([]);
  const [isFirst, setIsFirst] = useState(true);
  const dispatch = useDispatch();
  const { setQuizProgress } = bindActionCreators(actionCreators, dispatch);
  const quizProgress = useSelector((state) => state.quizProgress);

  const takeQuizQuestion = useSelector((state) => state.takeQuizQuestion);
  const quizGeneralSettings = useSelector((state) => state.quizGeneralSettings);

  const completeQuestion = useSelector((state) => state.completeQuestion);
  const isTimeOutForceSubmit = useSelector(
    (state) => state.isTimeOutForceSubmit,
  );
  const [options, setOptions] = useState({});
  let submitButtonRef = useRef(null);

  const handleChange = (event) => {
    if (event.target.checked) {
      setChoice([...choice, event.target.value]);
    } else {
      setChoice([...choice].filter((value) => value !== event.target.value));
    }
    if (isFirst) {
      setQuizProgress(
        (completeQuestion / quizGeneralSettings.selectnumberofquestion) * 100,
      );
      sessionStorage.setItem(
        "quizProgress",
        (completeQuestion / quizGeneralSettings.selectnumberofquestion) * 100,
      );
      setIsFirst(false);
    }
  };

  const handleSubmit = (event) => {
    handleTakeNextQuestion(takeQuizQuestion["question_id"], choice);
    setIsFirst(true);
    event.preventDefault();
  };

  const convertHtmlToText = (text) => {
    const ele = document.createElement("div");
    ele.innerHTML = text;
    return ele.innerText;
  };

  const shuffleObj = (sourceArray) => {
    if (
      quizGeneralSettings?.randomorder === "shuffle answers" ||
      quizGeneralSettings?.randomorder === "shuffle questions and answers"
    ) {
      let new_obj = {};
      while (true) {
        let random_number = Math.floor(
          Math.random() * (Object.keys(sourceArray).length - 1),
        );
        new_obj[Object.keys(sourceArray)[random_number]] =
          sourceArray[Object.keys(sourceArray)[random_number]];
        if (
          Object.keys(sourceArray).length - 1 ===
          Object.keys(new_obj).length
        ) {
          break;
        }
      }
      new_obj = { ...new_obj, ...sourceArray };
      setOptions(new_obj);
      return new_obj;
    }
    setOptions(sourceArray);
    return sourceArray;
  };

  useEffect(() => {
    shuffleObj(takeQuizQuestion["options"]);
  }, []);

  useEffect(() => {
    if (isTimeOutForceSubmit) {
      submitButtonRef?.current?.click();
    }
  }, [isTimeOutForceSubmit]);

  return (
    <div className="quizcontainer">
      <div className="quizcontainer">
        <div className="take-common-questions">
          <div
          style={{userSelect: "none"}}
            className="question-title"
            dangerouslySetInnerHTML={{
              __html: takeQuizQuestion["question_title"],
            }}
          ></div>
          {takeQuizQuestion?.question_image && (
            <div className="between-img">
              <img
                src={BACKEND_URL + "media/" + takeQuizQuestion?.question_image}
                alt=""
              />
            </div>
          )}

          <div className="options-div">
            {Object.entries(options).map(([key, value], index) => (
              <div className="options" key={index}>
                <input
                  type="checkbox"
                  className="choice"
                  id={key}
                  value={key}
                  onChange={handleChange}
                />
                <label style={{userSelect: "none"}} htmlFor={key} className="otp-number multi-response">
                  {String.fromCharCode(65 + index)}
                </label>
                <label
                style={{userSelect: "none"}}
                  htmlFor={key}
                  className="option"
                  dangerouslySetInnerHTML={{ __html: value }}
                ></label>
              </div>
            ))}
          </div>
          {takeQuizQuestion["show_explanation"] && (
            <div className="question-explanation">
              <div className="explanation-title">Explanation: </div>
              <div className="explanation-body">
                {convertHtmlToText(takeQuizQuestion["explanation_text"])}
              </div>
            </div>
          )}
          <button
            className={
              choice.length === 0 ? "btn-submit btn-disable" : "btn-submit"
            }
            ref={submitButtonRef}
            onClick={handleSubmit}
          >
            {quizGeneralSettings.selectnumberofquestion === completeQuestion
              ? "Submit"
              : "Next Question"}
          </button>
        </div>
      </div>
    </div>
  );
}
