import React, { useEffect, useState } from "react";
import "../../assets/styles/textFormatter.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faBold,
  faImage,
  faIndent,
  faItalic,
  faList,
  faList12,
  faUnderline,
} from "@fortawesome/free-solid-svg-icons";
import { BsSuperscript, BsSubscript } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { handleQuestionSubmit } from "../../utils/helpers";
import "../../assets/styles/commonQuestions.css";
import "../../assets/styles/index.css";
import { handleQuestionImageUpload } from "../../utils/helpers";

export default function TextFormatter(props) {
  const isExplanationOn = useSelector((state) => state.isExplanationOn);
  const dispatch = useDispatch();
  const { setIsExplanationOn, setImagePath, setErrorNotification } =
    bindActionCreators(actionCreators, dispatch);
  const imagePath = useSelector((state) => state.imagePath);
  const setQuestions = useSelector((state) => state.setQuestions);

  const ColorCodeList = [
    "#ffffcc",
    "#ccffcc",
    "#ccccff",
    "#ffccff",
    "#ffffe6",
    "#ccffff",
    "#eeffe6",
    "#ffe6ff",
    "#e6e6ff",
    "white",
  ];

  const [selectedColor, setSelectedColor] = useState("white");

  const tooltip = (
    <Tooltip id="tooltip" className="text-format-image-tooltip">
      Upload image to display between question and answer (optional)
    </Tooltip>
  );

  const handleUploadImage = (event) => {
    handleQuestionImageUpload({
      question_id: setQuestions,
      image: event.target.files[0],
    });
  };

  return (
    <>
      <div className="main-text-formatter">
        <div className="text-style">
          <FontAwesomeIcon size="lg" icon={faBold} />
          <FontAwesomeIcon size="lg" icon={faItalic} />
          <FontAwesomeIcon size="lg" icon={faUnderline} />
        </div>
        <div className="text-align">
          <FontAwesomeIcon size="lg" icon={faAlignLeft} />
          <FontAwesomeIcon size="lg" icon={faAlignCenter} />
          <FontAwesomeIcon size="lg" icon={faAlignRight} />
          <FontAwesomeIcon size="lg" icon={faAlignJustify} />
        </div>
        <div className="make-list">
          <FontAwesomeIcon size="lg" icon={faList} />
          <FontAwesomeIcon size="lg" icon={faList12} />
        </div>
        <div
          className={
            !setQuestions ? "text-head-bottom last-child" : "text-head-bottom "
          }
        >
          <BsSuperscript fontSize={"26px"} />
          <BsSubscript fontSize={"26px"} />
        </div>
        <div
          className={
            props?.questionChangeComponent.type.name !== "EditQuiz" ||
            props?.questionChangeComponent.type.name
              ? "color-picker last-child"
              : "color-picker"
          }
        >
          <span
            className="color-select"
            style={{ backgroundColor: selectedColor }}
          ></span>
          <div className="color-picker-selector">
            {ColorCodeList.map((color, index) => (
              <div
                key={index}
                className="color-picker-item"
                style={{ backgroundColor: color }}
                onClick={() => {
                  setSelectedColor(color);
                }}
              ></div>
            ))}
          </div>
          <p>Background</p>
        </div>
        {props?.questionChangeComponent?.type?.name !== "EditQuiz" &&
          props?.questionChangeComponent?.type?.name !== "AddDocument" && (
            <>
              <div
                className={
                  imagePath ? "image-show active-tool-bar" : "image-show"
                }
              >
                <OverlayTrigger placement="bottom" overlay={tooltip}>
                  <label className={"btn-with-img"}>
                    <FontAwesomeIcon icon={faImage} size="xl" />
                    <p>Upload Image</p>
                    <input
                      type="file"
                      className="type-file-upload"
                      value={""}
                      id="file-upload"
                      onChange={handleUploadImage}
                      accept="image/*"
                    />
                  </label>
                </OverlayTrigger>
              </div>
              <div
                className={
                  isExplanationOn
                    ? "add-explain active-tool-bar"
                    : "add-explain"
                }
                onClick={() => {
                  setIsExplanationOn(!isExplanationOn);
                  handleQuestionSubmit(setQuestions, {
                    show_explanation: !isExplanationOn,
                  });
                }}
              >
                <FontAwesomeIcon size="lg" icon={faIndent} />
                <p className="active-add-to-explain">
                  Add Explanation for Answer{" "}
                </p>
              </div>
            </>
          )}
      </div>
    </>
  );
}
