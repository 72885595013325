import * as React from "react";

const FullscreenExitDialog = ({ onClose, onSubmit, children, ...rest }) => {
  const handleTimeOutOk = () => {
    onSubmit();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <div className="upper-main-timeout" {...rest}>
      <div className="main-tab-change">
        <div className="title">Navigating Away from Quiz Tab</div>
        <div className="warn">
          Are you sure you want to exit? Exiting now will
          automatically submit your quiz and end your session.
        </div>
        {children}
        <div className="timeout">
          <button onClick={handleTimeOutOk}>Submit Answers</button>
          <button onClick={handleCancel}>Fullscreen</button>
        </div>
      </div>
    </div>
  );
};

export default FullscreenExitDialog;
