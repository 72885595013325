import React, { useEffect, useRef, useState } from "react";
import QuizApi from "../services/QuizApi";
import "../assets/styles/Managequiz.css";
import { format } from "date-fns";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Modal from "react-modal";
import { ManageQuizNavbar } from "./Common/Navbar";
import {
  FaAnglesRight,
  FaAnglesLeft,
  FaMagnifyingGlass,
} from "react-icons/fa6";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
  faPen,
  faAngleLeft,
  faAngleRight,
  faXmark,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import MyTeamModal from "./Modals/MyTeamModal";
import Cookies from "js-cookie";
import { bindActionCreators } from "redux";
import { actionCreators } from "../state";
import { useDispatch } from "react-redux";
import { FiShare2 } from "react-icons/fi";
import { GoGear } from "react-icons/go";
import { MdContentCopy } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";

function decodeHtmlEntities(text) {
  const element = document.createElement("div");
  if (text) {
    element.innerHTML = text;
    return element.textContent;
  }
  return text;
}

export function QuizList() {
  const { page } = useParams();
  const [quizdata, setQuizData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [visibleDiv, setVisibleDiv] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const localStorageKey = "userInteger";
  const entriesPerPage = parseInt(
    localStorage.getItem(localStorageKey) || "20",
  );
  const totalPageCount = useRef(1);
  const [isMyTeamPopupOpen, setMyTeamIsPopupOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const fromParamSearch = searchParams.get("search");
  const [searchTerm, setSearchTerm] = useState(fromParamSearch || "");
  const [finalSearchTerm, setFinalSearchTerm] = useState(fromParamSearch || "");
  const currentPage = Number(page) || 0;

  const navigate = useNavigate();
  useEffect(() => {
    localStorage.setItem("is_first_time", true);
  }, []);

  let userData = { user: {} };

  const encodedData = Cookies.get("authToken");
  if (encodedData) {
    const decodedData = decodeURIComponent(encodedData);
    userData = JSON.parse(decodedData);
  }

  const dispatch = useDispatch();
  const { quizQuestionId, setFirstQuestionId, setNavbarActiveTab } =
    bindActionCreators(actionCreators, dispatch);
  quizQuestionId(false);
  setFirstQuestionId(0);
  setNavbarActiveTab("quiz");

  const handlePageChange = (selected) => {
    if (selected >= 0 && selected < totalPageCount.current) {
      navigate(`/manage/${selected}`);
    }
  };

  const [isFromCreateQuiz, setIsFromCreateQuiz] = useState(false);

  const deleteQuiz = async (quizId) => {
    try {
      const response = await QuizApi.delete(`quiz/${quizId}/`);
      setQuizData(quizdata.filter((quiz) => quiz.id !== quizId));
    } catch (error) {
      console.error("Error deleting quiz:", error);
    }
  };

  const hideDiv = () => {
    setVisibleDiv(null);
  };

  const handleSelectChange = (event, key, quizId) => {
    const { value } = event.target;
    setSelectedValue(value);

    QuizApi.patch(`/quiz/${quizId}/`, { [key]: value })
      .then((response) => {
        setQuizData((prevQuizData) => {
          return prevQuizData.map((quiz) => {
            if (quiz.id === quizId) {
              return { ...quiz, [key]: value };
            }
            return quiz;
          });
        });
      })
      .catch((error) => {
        console.error(`Error updating ${key} in the backend: ${error}`);
      });
  };

  const openMyTeamPopup = () => {
    setMyTeamIsPopupOpen(true);
  };

  const closeMyTeamPopup = () => {
    setMyTeamIsPopupOpen(false);
  };

  const order = sessionStorage.getItem("mqs") || "-datemodified";
  if (!sessionStorage.getItem("mqs")) {
    sessionStorage.setItem("mqs", order);
  }

  const [sortOrder, setSortOrder] = useState({
    key: order.replace("-", ""),
    ascending: !order.startsWith("-"),
  });

  const handleSort = (key) => {
    const ass_sort = ["quiztitle", "quizstatus"];
    if (key === sortOrder.key) {
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        ascending: !prevSortOrder.ascending,
      }));
    } else {
      if (!ass_sort.includes(key)) {
        setSortOrder({
          key,
          ascending: false,
        });
      } else {
        setSortOrder({
          key,
          ascending: true,
        });
      }
    }
  };

  useEffect(() => {
    sessionStorage.setItem(
      "mqs",
      `${sortOrder.ascending ? "" : "-"}${sortOrder.key}`,
    );
  }, [sortOrder]);
  const renderSortingIcon = (key) => {
    if (sortOrder.key === key) {
      if (sortOrder.ascending) {
        return (
          <FontAwesomeIcon
            style={{ height: "17px", paddingLeft: "10px" }}
            icon={faSortUp}
          />
        );
      } else {
        return (
          <FontAwesomeIcon
            style={{ height: "17px", paddingLeft: "10px" }}
            icon={faSortDown}
          />
        );
      }
    } else {
      return (
        <FontAwesomeIcon
          style={{ height: "17px", paddingLeft: "10px" }}
          icon={faSort}
        />
      );
    }
  };

  const handleCreateQuiz = async () => {
    try {
      const response = await QuizApi.post("quiz/", {
        user: userData?.user?.id,
        quiztitle: "",
      });
      setIsFromCreateQuiz(true);
      navigate(`/quiz/${response.data.id}`);
    } catch (error) {
      console.error("Error creating quiz:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    const sort = sortOrder.ascending ? sortOrder.key : `-${sortOrder.key}`;
    let m_page = page || 0;
    let url = `quiz/${userData.user.id}/get_quiz_by_user/?&sort=${sort}&page=${parseInt(m_page) + 1}&limit=${entriesPerPage}`;
    if (finalSearchTerm) {
      url += `&search=${finalSearchTerm}`;
    }
    QuizApi.get(url)
      .then((response) => {
        setQuizData(response.data?.data);
        totalPageCount.current = response.data?.total_pages;
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [page, sortOrder, entriesPerPage, finalSearchTerm]);

  const handleSearch = () => {
    navigate(`/manage/${0}?search=${searchTerm}`);
    setFinalSearchTerm(searchTerm);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    navigate(`/manage/${0}`);
    setFinalSearchTerm("");
  };

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFinalSearchTerm("");
      navigate(`/manage/${0}`);
    }
  }, [searchTerm]);

  return (
    <div>
      <ManageQuizNavbar onOpen={openMyTeamPopup} />
      <div className="Managequiz-container">
        <Modal
          isOpen={isMyTeamPopupOpen}
          onRequestClose={closeMyTeamPopup}
          contentLabel="My Team Popup"
          ariaHideApp={false}
          id="myteam_popup"
        >
          <MyTeamModal onClose={closeMyTeamPopup} onOpen={openMyTeamPopup} />
        </Modal>
        <div className="managequiz_header">
          <h1></h1>
          <h1>My Quizzes</h1>
          <div className="search_bar">
            <button
              title="Search"
              type="button"
              className="button_search"
              onClick={handleSearch}
            >
              <FaMagnifyingGlass />
            </button>
            <input
              type="text"
              placeholder="Search by quiz name."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={(e) => {
                e.key === "Enter" && handleSearch();
              }}
            />
            {searchTerm && (
              <button
                title="Clear Search"
                type="button"
                className="button_clear"
                onClick={handleClearSearch}
              >
                <FontAwesomeIcon icon={faXmark} size="xl" />
              </button>
            )}
          </div>
          {/* <a onClick={handleCreateQuiz}>
            <button id="create_quiz_mq">
              <span>Create a New Quiz</span>
            </button>
          </a> */}
        </div>
        <div className="managequiz-data">
          <table className="managequiztable">
            <thead>
              <tr>
                <th
                  style={{ width: "30%" }}
                  onClick={() => handleSort("quiztitle")}
                >
                  Quiz Name {renderSortingIcon("quiztitle")}
                </th>
                <th
                  style={{ width: "10%", minWidth: "13%" }}
                  onClick={() => handleSort("quizstatus")}
                >
                  Access {renderSortingIcon("quizstatus")}
                </th>
                <th
                  style={{ width: "18%" }}
                  onClick={() => handleSort("datemodified")}
                >
                  Modified Date {renderSortingIcon("datemodified")}
                </th>
                <th
                  style={{ width: "18%" }}
                  onClick={() => handleSort("date_quiz_was_taken")}
                >
                  Date Quiz was Taken {renderSortingIcon("date_quiz_was_taken")}
                </th>
                <th
                  style={{ width: "12%" }}
                  onClick={() => handleSort("report_count")}
                >
                  Quiz Reports {renderSortingIcon("report_count")}
                </th>
                <th style={{ width: "10%" }}>Actions</th>
              </tr>
            </thead>
            <tbody className={`${loading && "manage-loading"}`}>
              {loading ? (
                <tr className="loading-tr">
                  <td colSpan={6}>
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      size="2xl"
                      className="loading-icon"
                    />
                  </td>
                </tr>
              ) : (
                <>
                  {quizdata?.length === 0 && finalSearchTerm.trim() ? (
                    <div className="btncreatequiz_div">
                      <p>No quizzes found for "{finalSearchTerm}"</p>
                      <button
                        onClick={handleClearSearch}
                        className="btn btn-danger"
                      >
                        Clear Search
                      </button>
                    </div>
                  ) : (
                    quizdata?.length < 1 && (
                      <div className="btncreatequiz_div">
                        <p> Your quizzes will appear here</p>
                        <button
                          onClick={handleCreateQuiz}
                          id="create_quiz_mq"
                          className="btncreatequiz_once"
                        >
                          <span>Create your first quiz</span>
                        </button>
                      </div>
                    )
                  )}
                  {quizdata?.map((quiz) => (
                    <tr key={quiz.id}>
                      <Link className="iconlink" to={`/quiz/${quiz.id}`}>
                        <td className="quiztitle">
                          {decodeHtmlEntities(quiz.quiztitle)}
                        </td>
                      </Link>
                      <td style={{ color: "#0082fa", cursor: "pointer" }}>
                        <CustomDropdown
                          options={[
                            "Draft",
                            "Live",
                            "Paused",
                            "Scheduled",
                            "Closed",
                          ]}
                          selectedValue={quiz.quizstatus}
                          onValueChange={(selectedValue) =>
                            handleSelectChange(
                              { target: { value: selectedValue } },
                              "quizstatus",
                              quiz.id,
                            )
                          }
                        />
                      </td>
                      <td>
                        {format(
                          new Date(quiz.datemodified),
                          "dd MMM yyyy hh:mm aa",
                        )}
                      </td>
                      <td>
                        {quiz.date_quiz_was_taken
                          ? format(
                              new Date(quiz.date_quiz_was_taken),
                              "dd MMM yyyy hh:mm aa",
                            )
                          : "-"}
                      </td>
                      <td>
                        <Link
                          style={{ textDecoration: "none" }}
                          to={`/quiz-result/${quiz.id}/0`}
                        >
                          {quiz?.report_count}
                        </Link>
                      </td>
                      <td style={{ display: "inline-block" }}>
                        <div className="league__champs">
                          <div className="quiz_toolbox" id="">
                            <Link className="iconlink" to={`/quiz/${quiz.id}`}>
                              <FontAwesomeIcon
                                className="image_on icons edit"
                                color="#052949"
                                icon={faPen}
                              />
                            </Link>
                            <Link className="iconlink">
                              <FiShare2
                                className="image_on icons share"
                                color="#052949"
                              />
                            </Link>
                            <li key={quiz.id} style={{ listStyle: "none" }}>
                              <Link
                                className="iconlink"
                                to={`/general-settings/${quiz.id}`}
                              >
                                <GoGear
                                  className="image_on icons settings"
                                  color="#052949"
                                />
                              </Link>
                            </li>
                            <Link className="iconlink">
                              <MdContentCopy
                                className="image_on icons duplicate"
                                color="#052949"
                              />
                            </Link>
                            <Link
                              className="iconlink"
                              id=""
                              onClick={() => setVisibleDiv(quiz.id)}
                            >
                              <RiDeleteBin6Line
                                className="image_on icons delete"
                                color="#052949"
                              />
                            </Link>
                          </div>
                          <div
                            className="Delete_popup"
                            style={{
                              display:
                                visibleDiv === quiz.id ? "block" : "none",
                            }}
                            id="delete"
                          >
                            <h3>Are you sure you want to delete this quiz?</h3>
                            <p style={{ marginBottom: "0px" }}>
                              All quiz reports and data will be removed
                              permanently and will not be recoverable.
                              <br></br>You may download all quiz reports now
                              before deleting.
                            </p>
                            <br></br>
                            <button id="delete" className="afterdelete">
                              Download Reports
                            </button>
                            <button
                              className="afterdelete"
                              onClick={() => deleteQuiz(quiz.id)}
                            >
                              Delete
                            </button>
                            <button className="afterdelete" onClick={hideDiv}>
                              Cancel
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        {totalPageCount.current >= 1 && (
          <div className="pagination" style={{ marginTop: "30px" }}>
            <div style={{ width: "100%", textAlign: "left" }}>
              <PageSelect />
            </div>
            {totalPageCount.current > 1 && (
              <>
                <button
                  className="previouspage pagelink"
                  onClick={() => navigate(`/manage/${0}`)}
                >
                  <FaAnglesLeft style={{ fontSize: "25px" }} />
                </button>
                <CustomPagination
                  currentPage={currentPage}
                  pageCount={totalPageCount.current}
                  onPageChange={handlePageChange}
                />
                <button
                  className="nextpage pagelink"
                  onClick={() =>
                    navigate(`/manage/${totalPageCount.current - 1}`)
                  }
                >
                  <FaAnglesRight style={{ fontSize: "25px" }} />
                </button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export function CustomPagination({ currentPage, pageCount, onPageChange }) {
  const visiblePageCount = 5;
  let startPage = currentPage - Math.floor(visiblePageCount / 2);
  startPage = Math.max(startPage, 0);
  const endPage = startPage + visiblePageCount;

  if (endPage > pageCount) {
    startPage -= endPage - pageCount;
  }

  const pageLinks = [];
  for (let i = startPage; i < endPage; i++) {
    if (i >= 0 && i < pageCount) {
      const isCurrent = i === currentPage;
      pageLinks.push(
        <button
          className={`pagenumber ${isCurrent ? "manage_active" : ""}`}
          key={i}
          onClick={() => onPageChange(i)}
        >
          {i + 1}
        </button>,
      );
    }
  }

  return (
    <div className="custom-pagination">
      <button
        className="pagelink previouspage"
        onClick={() => onPageChange(Math.max(currentPage - 1, 0))}
        disabled={currentPage === 0}
      >
        <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "25px" }} />
      </button>
      {pageLinks}
      <button
        className="pagelink nextpage"
        onClick={() => onPageChange(Math.min(currentPage + 1, pageCount - 1))}
        disabled={currentPage === pageCount - 1}
        style={{ marginLeft: "0px" }}
      >
        <FontAwesomeIcon icon={faAngleRight} style={{ fontSize: "25px" }} />
      </button>
    </div>
  );
}

export function CustomDropdown({ options, selectedValue, onValueChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const dropdownHeaderClass =
    selectedValue.toLowerCase() === "live"
      ? "dropheader live"
      : selectedValue.toLowerCase() === "draft"
        ? "dropheader draft"
        : selectedValue.toLowerCase() === "paused"
          ? "dropheader paused"
          : selectedValue.toLowerCase() === "scheduled"
            ? "dropheader scheduled"
            : selectedValue.toLowerCase() === "closed"
              ? "dropheader closed"
              : "dropheader";

  const handleSelect = (option) => {
    onValueChange(option);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const handleDocumentClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  return (
    <div className="customdropdown" ref={dropdownRef}>
      <div className={dropdownHeaderClass} onClick={toggleDropdown}>
        {selectedValue}
        <span className={`icon ${isOpen ? "openicon" : "closedicon"}`}></span>
      </div>
      {isOpen && (
        <ul className="dropdownoptions">
          {options.map((option) => (
            <li
              key={option}
              onClick={() => handleSelect(option)}
              className={
                option === "Closed" ||
                option === "Scheduled" ||
                option === "Paused"
                  ? "disabled"
                  : ""
              }
              style={{
                pointerEvents:
                  option === "Closed" ||
                  option === "Scheduled" ||
                  option === "Paused"
                    ? "none"
                    : "auto",
              }}
              title={
                option === "Closed" ? "This can't be changed manually" : null
              }
              id={option === "Closed" ? "closed-option" : null}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
export function PageSelect() {
  const [selectedValue, setSelectedValue] = useState("");
  const localStorageKey = "userInteger";
  const navigate = useNavigate();

  useEffect(() => {
    const savedValue = localStorage.getItem(localStorageKey);
    if (savedValue) {
      setSelectedValue(savedValue);
    }
  }, []);

  const handleSelectChange = (e) => {
    const newValue = e.target.value;
    setSelectedValue(newValue);
    localStorage.setItem(localStorageKey, parseInt(newValue, 10).toString());
    navigate(`/manage/${0}`);
  };

  return (
    <div>
      <label
        htmlFor="integerSelect"
        style={{ fontWeight: "600", color: "#052949", fontSize: "19px" }}
      >
        View quizzes per page: &nbsp; &nbsp;
      </label>
      <select
        id="integerSelect"
        value={selectedValue}
        onChange={handleSelectChange}
      >
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </div>
  );
}
