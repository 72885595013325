import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function Loading() {
  return (
    <div className="main-loading">
      <FontAwesomeIcon
        icon={faSpinner}
        spin
        size="2xl"
        className="loading-icon"
      />
    </div>
  );
}
