import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import React, { useEffect, useState } from "react";
import QuizTakeNavbar from "./common/QuizTakeNavbar";
import MultipleChoiceQuestion from "./common/MultipleChoiceQuestion";
import "../../assets/styles/takeQuiz/takeCommonQuestions.css";
import { actionCreators } from "../../state";
import EssayQuestion from "./common/EssayQuestion";
import Loading from "./common/Loading";
import "../../assets/styles/takeQuiz/takeQuizMain.css";
import EndQuiz from "./EndQuiz";
import { useNavigate, useParams } from "react-router-dom";
import YesNoQuestion from "./common/YesNoQuestion";
import TrueFalseQuestion from "./common/TrueFalseQuestion";
import MultipleResponseQuestion from "./common/MultipleResponseQuestion";
import AddDocumentQuestion from "./common/AddDocumentQuestion";
import QuizApi from "../../services/QuizApi";
import OfflineUserDialogue from "./OfflineUserDialogue";
import FllscreenExitDialog from "./FullScreenExitDialog";
import {
  setIsQuizSubmitted,
  useFullscreenDialogOpen,
  setFullscreenSubmittedInSessionStorage,
  setIsFirtRender,
  getIsFirstRender,
} from "../../contexts/FullscreenSubmitted";

export default function TakeQuizMain() {
  const dispatch = useDispatch();
  const takeQuizQuestion = useSelector((state) => state.takeQuizQuestion);
  const { setIsTimeOutForceSubmit, setIsSecuritySubmit } = bindActionCreators(
    actionCreators,
    dispatch,
  );
  const [questionComponent, setQuestionComponent] = useState("");
  const isLoading = useSelector((state) => state.isLoading);
  const quizBrowserSettings = useSelector((state) => state.quizBrowserSettings);
  const [isTabChanging, setIsTabChanging] = useState(false);
  const [tabChangingTime, setTabChangingTime] = useState(10);
  const { quizId } = useParams();
  let navigate = useNavigate();
  const [timeInterval, setTimeInterval] = useState(false);
  const [isMouseLeaveOn, setIsMouseLeaveOn] = useState(false);
  const quizResult = useSelector((state) => state.quizResult);

  const isFullscreenDialogOpen = useFullscreenDialogOpen(
    (state) => state.isFullscreenDialogOpen,
  );
  const setIsFullscreenDialogOpen = useFullscreenDialogOpen(
    (state) => state.setIsFullscreenDialogOpen,
  );

  const [securityAlert, setSecurityAlert] = useState(0);

  useEffect(() => {
    switch (takeQuizQuestion?.question_type) {
      case "multiple_choice":
        setQuestionComponent(<MultipleChoiceQuestion />);
        break;
      case "yes_no":
        setQuestionComponent(<YesNoQuestion />);
        break;
      case "essay":
        setQuestionComponent(<EssayQuestion />);
        break;
      case "true_false":
        setQuestionComponent(<TrueFalseQuestion />);
        break;
      case "multiple_response":
        setQuestionComponent(<MultipleResponseQuestion />);
        break;
      case "add_document":
        setQuestionComponent(<AddDocumentQuestion />);
        break;
      case "end_quiz":
        setQuestionComponent(
          <EndQuiz
            setIsMouseLeaveOn={setIsMouseLeaveOn}
            setIsTabChanging={setIsTabChanging}
            customMouseLeaveEvent={customMouseLeaveEvent}
            handleFullscreenChange={handleFullscreenChange}
          />,
        );
        break;
      default:
        navigate(`/quiz-title/${quizId}`);
        break;
    }
  }, [takeQuizQuestion]);

  useEffect(() => {
    if (isLoading) {
      setQuestionComponent(<Loading />);
    }
  }, [isLoading]);

  const customMouseLeaveEvent = (e) => {
    if (!isTabChanging) {
      setIsTabChanging(true);
    }
  };

  const handleFullscreenChange = (e) => {
    const temp =
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
        ? true
        : false;

    setIsFullscreenDialogOpen(!temp);
    if (!temp) {
      setIsTabChanging(true);
    }
  };

  useEffect(() => {
    const disableRightClick = (event) => {
      event.preventDefault();
    };
  
    document.addEventListener("contextmenu", disableRightClick);
  
    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);

  useEffect(() => {}, [isFullscreenDialogOpen]);

  useEffect(() => {
    const disableInspect = (e) => {
      // Disable right-click context menu
      if (e.type === "contextmenu") {
        e.preventDefault();
      }

      // Disable F12, F11, Ctrl+Shift+I, Ctrl+Shift+J, Ctrl+U, Alt+Tab, Alt+Ctrl+Tab
      if (e.type === "keydown") {
        if (
          e.key === "F12" || // F12
          e.key === "F11" || // F11
          e.key === "Escape" || // Escape
          (e.ctrlKey && e.shiftKey && e.key === "I") || // Ctrl+Shift+I
          (e.ctrlKey && e.shiftKey && e.key === "J") || // Ctrl+Shift+J
          (e.ctrlKey && e.key === "U") || // Ctrl+U
          (e.altKey && e.key === "Tab") || // Alt+Tab
          (e.altKey && e.ctrlKey && e.key === "Tab") // Alt+Ctrl+Tab
        ) {
          e.preventDefault();
        }
      }
    };

    // Add event listeners
    document.addEventListener("contextmenu", disableInspect);
    document.addEventListener("keydown", disableInspect);

    // Cleanup function to remove event listeners when component unmounts
    return () => {
      document.removeEventListener("contextmenu", disableInspect);
      document.removeEventListener("keydown", disableInspect);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("contextmenu", function (event) {
      event.preventDefault();
    });

    document.addEventListener("dragstart", (e) => {
      e.preventDefault();
    });

    document.addEventListener("keydown", function (event) {
      if (
        event.ctrlKey &&
        (event.key === "r" || event.key === "R" || event.shiftKey)
      ) {
        event.preventDefault();
      }
    });

    if (quizBrowserSettings?.disableprint) {
      document.body.classList.add("disable-print");
      document.addEventListener("keydown", (e) => {
        if (e.keyCode === 80 && (e.ctrlKey || e.metaKey)) {
          e.preventDefault();
          if (e.shiftKey) {
            e.stopPropagation();
          }
        }
      });
    }
    if (quizBrowserSettings?.disablecopy) {
      document.addEventListener("keydown", (e) => {
        if (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) {
          e.preventDefault();
          if (e.shiftKey) {
            e.stopPropagation();
          }
        }
      });
      document.addEventListener("copy", function (event) {
        event.preventDefault();
      });
    }

    if (quizBrowserSettings?.switchingbrowsertabs) {
      document.documentElement.addEventListener(
        "mouseleave",
        customMouseLeaveEvent,
      );
      setIsMouseLeaveOn(true);
      document.addEventListener("fullscreenchange", handleFullscreenChange);
      document.addEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange,
      );
      document.addEventListener("mozfullscreenchange", handleFullscreenChange);
      document.addEventListener("MSFullscreenChange", handleFullscreenChange);
      setFullscreenSubmittedInSessionStorage(true);
    } else {
      document.documentElement.removeEventListener(
        "mouseleave",
        customMouseLeaveEvent,
      );
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange,
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange,
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullscreenChange,
      );
      // Check if the document is in fullscreen mode and exit if necessary
      if (
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      ) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          // Firefox
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          // Chrome, Safari and Opera
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          // IE/Edge
          document.msExitFullscreen();
        }
      }
    }

    return () => {
      if (quizBrowserSettings?.switchingbrowsertabs) {
        document.documentElement.removeEventListener(
          "mouseleave",
          customMouseLeaveEvent,
        );
        document.removeEventListener(
          "fullscreenchange",
          handleFullscreenChange,
        );
        document.removeEventListener(
          "webkitfullscreenchange",
          handleFullscreenChange,
        );
        document.removeEventListener(
          "mozfullscreenchange",
          handleFullscreenChange,
        );
        document.removeEventListener(
          "MSFullscreenChange",
          handleFullscreenChange,
        );
      }
    };
  }, []);

  const exitFullscreen = () => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  const intervalForTabChange = () => {
    let tabTime = 9;
    let myTimeInterval = setInterval(() => {
      setTabChangingTime(tabTime);
      tabTime--;
      if (tabTime < 0) {
        setIsFullscreenDialogOpen(false);
        setIsQuizSubmitted(true);
        clearInterval(myTimeInterval);
        setIsTimeOutForceSubmit(true);
        setIsSecuritySubmit(true);
        setIsTabChanging(false);
        setIsFirtRender(true);
        setIsMouseLeaveOn(false);
        setTabChangingTime(10);
        exitFullscreen();
        document.removeEventListener("mouseleave", customMouseLeaveEvent);
        document.removeEventListener(
          "fullscreenchange",
          handleFullscreenChange,
        );
        document.removeEventListener(
          "webkitfullscreenchange",
          handleFullscreenChange,
        );
        document.removeEventListener(
          "mozfullscreenchange",
          handleFullscreenChange,
        );
        document.removeEventListener(
          "MSFullscreenChange",
          handleFullscreenChange,
        );
      }
    }, 1000);
    setTimeInterval(myTimeInterval);
  };

  const updateSecurityAlert = () => {
    if (takeQuizQuestion?.question_type !== "end_quiz") {
      QuizApi.patch(`/quiz-result/${quizResult?.id}/`, {
        securityalerts: securityAlert + 1,
      })
        .then((response) => {
          console.log("");
        })
        .catch((error) => {
          console.log(error);
        });
      setSecurityAlert(securityAlert + 1);
    }
  };

  useEffect(() => {
    if (isTabChanging) {
      if (isMouseLeaveOn) {
        updateSecurityAlert();
        intervalForTabChange();
      }
    } else {
      clearInterval(timeInterval);
    }
  }, [isTabChanging]);

  const handleTimeOutOk = () => {
    setTabChangingTime(10);
    setIsTabChanging(false);
  };

  sessionStorage.setItem("isTabChanging", isTabChanging);
  sessionStorage.setItem("isMouseLeaveOn", isMouseLeaveOn);
  sessionStorage.setItem("isFullscreenDialogOpen", isFullscreenDialogOpen);

  return (
    <>
      {takeQuizQuestion?.question_type !== "end_quiz" && <QuizTakeNavbar />}
      {((isTabChanging && isMouseLeaveOn) || isFullscreenDialogOpen) &&
        takeQuizQuestion?.question_type !== "end_quiz" && (
          <FllscreenExitDialog
            onClose={() => {
              setIsFullscreenDialogOpen(false);
              handleTimeOutOk();
              setIsFirtRender(false);
              const elem = document.documentElement;
              if (elem.requestFullscreen) {
                elem.requestFullscreen();
              } else if (elem.mozRequestFullScreen) {
                // Firefox
                elem.mozRequestFullScreen();
              } else if (elem.webkitRequestFullscreen) {
                // Chrome, Safari and Opera
                elem.webkitRequestFullscreen();
              } else if (elem.msRequestFullscreen) {
                // IE/Edge
                elem.msRequestFullscreen();
              }
            }}
            onSubmit={() => {
              setIsFullscreenDialogOpen(false);
              setIsTimeOutForceSubmit(true);
              setIsSecuritySubmit(true);
              setIsTabChanging(false);
              setIsFirtRender(true);
              document.removeEventListener("mouseleave", customMouseLeaveEvent);
              document.removeEventListener(
                "fullscreenchange",
                handleFullscreenChange,
              );
              document.removeEventListener(
                "webkitfullscreenchange",
                handleFullscreenChange,
              );
              document.removeEventListener(
                "mozfullscreenchange",
                handleFullscreenChange,
              );
              document.removeEventListener(
                "MSFullscreenChange",
                handleFullscreenChange,
              );
            }}
          >
            <div className="timeout">
              <p>{tabChangingTime} Seconds remaining.</p>
            </div>
          </FllscreenExitDialog>
        )}
      {questionComponent && questionComponent}
      <OfflineUserDialogue
        setIsMouseLeaveOn={setIsMouseLeaveOn}
        setIsTabChanging={setIsTabChanging}
      />
    </>
  );
}
