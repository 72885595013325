import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators } from "../state";
import "../assets/styles/loginSignup.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faClose } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import QuizApi from "../services/QuizApi.js";
import { loginSuccess } from "../state/authslice.js";
import Cookies from "js-cookie"; // Import the js-cookie library
import { useNavigate } from "react-router-dom";

export function PasswordField({ placeholder, value, onChange }) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="form-outline">
      <input
        type={showPassword ? "text" : "password"}
        name="password"
        id="password"
        className="form-control-m border-top-0 border-right-0 border-left-0 inputtypepassword"
        placeholder={placeholder}
        required={true}
        value={value}
        onChange={onChange}
        maxLength={30}
      />
      <FontAwesomeIcon
        style={{ cursor: "pointer" }}
        icon={showPassword ? faEye : faEyeSlash}
        className="eye-icon"
        onClick={togglePassword}
        id="togglePassword"
      />
    </div>
  );
}

export function EmailField({ placeholder, value, onChange }) {
  return (
    <div className="form-outline">
      <input
        type="email"
        name="email"
        id="email"
        className="form-control-m border-top-0 border-right-0 border-left-0 inputtypeemail"
        placeholder={placeholder}
        required={true}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

export function SocialOptions() {
  return (
    <div className="socialdiv" style={{ gridGap: "0.5em" }}>
      <button type="button" className="socialmediaicons">
        {" "}
        <img
          src="/images/gmail.png"
          className=" socialdivimages Googleimg"
          alt=""
        />{" "}
        Log In With Google
      </button>
      <button type="button" className="socialmediaicons">
        {" "}
        <img
          src="/images/facebook.png"
          className="socialdivimages facebookimg"
          alt=""
        />
        Log In With Facebook{" "}
      </button>
      <button type="button" className="socialmediaicons">
        <img
          src="/images/microsoft.png"
          className="socialdivimages microsoftimg"
          alt=""
        />{" "}
        Log In With Microsoft
      </button>
    </div>
  );
}

export function LoginSignupButton(props) {
  return (
    <button
      type="submit"
      className="submitbtn"
      name="loginform"
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
}

export default function LoginModal(props) {
  const dispatch = useDispatch();
  const [formFields, setFormFields] = useState({});
  const [loginStatus, setLoginStatus] = useState("");
  const navigate = useNavigate(); // Initialize the navigate function

  const handleEmailChange = (e) => {
    setFormFields({ ...formFields, username: e.target.value });
  };

  const handlePasswordChange = (e) => {
    setFormFields({ ...formFields, password: e.target.value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const loginData = await QuizApi.post("login/", formFields);

      if (loginData.status === 200 && loginData.data && loginData.data.token) {
        const data = loginData.data;
        const { token } = data;

        localStorage.setItem("token", loginData.data.token);

        Cookies.set(
          "authToken",
          JSON.stringify({ token, user: data.user_data }),
          { expires: 7 },
        );

        dispatch(loginSuccess({ token, user: data.user_data }));

        setLoginStatus("Logging in...");
        props.setIsLoggedIn(loginData.data.token);
        setTimeout(() => {
          props.onClose();
          navigate("/manage");
          window.location.reload();
        }, 2000);
      } else {
        console.error("Invalid login response:", loginData);
        setLoginStatus("Login failed. Please try again.");
      }
    } catch (error) {
      console.error("Login error:", error);
      if (error.response && error.response.status === 404) {
        setLoginStatus("User with this email address does not exist");
      } else if (error.response && error.response.status === 401) {
        setLoginStatus("Invalid email or password. Please try again.");
      } else {
        console.error("Unknown error:", error);
      }
    }
  };

  return (
    <div
      className="outside-column"
      style={{
        border: "1px solid #cecece",
        height: "auto",
        padding: 10,
        marginBottom: 10,
      }}
    >
      <div
        className="login-column"
        style={{ paddingLeft: 40, paddingRight: 40, height: "auto" }}
      >
        <FontAwesomeIcon
          style={{ cursor: "pointer", position: "absolute" }}
          icon={faClose}
          className="close-icon"
          onClick={props.onClose}
        />
        <div
          style={{ width: "100%", marginBottom: 10 }}
          className="logintittle"
        >
          <h5
            id="h5"
            style={{
              fontSize: 24,
              fontStyle: "normal",
              fontWeight: 600,
              letterSpacing: "0.01em",
              color: "#052949",
              textAlign: "center !important",
              marginTop: 17,
              marginBottom: 25,
            }}
          >
            Login To Your Quiz Secure Account
          </h5>
        </div>
        <div id="form">
          <form method="POST" id="loginform" onSubmit={handleFormSubmit}>
            <input
              type="hidden"
              name="csrfmiddlewaretoken"
              defaultValue="IFZt3z81DvQlev4BaUDq0mMnF9zjwMxQlqj26d6gKNlocPL3RiSovnUexwDjeQ2J"
            />
            <EmailField
              placeholder="Enter Your Email"
              onChange={handleEmailChange}
            />
            <PasswordField
              placeholder="Your Password"
              onChange={handlePasswordChange}
              maxLength={30}
            />
            <div className="Rememberme">
              <div className="form-check ">
                <input
                  type="checkbox"
                  defaultValue=""
                  style={{ cursor: "pointer" }}
                  id="Remembermecheckbox"
                />
                <label
                  className="form-check-label"
                  style={{ fontSize: "17.1px !important" }}
                >
                  {" "}
                  Remember Me{" "}
                </label>
              </div>
              <div className="Forgotpassword">
                <div>
                  <a href="#" style={{ textDecoration: "underline" }}>
                    <span className="modal-close2 passwordlauncher">
                      Forgot password?
                    </span>
                  </a>
                  <br />
                </div>
              </div>
            </div>
            {loginStatus && (
              <div
                className={`login-status ${
                  loginStatus === "Logging in..." ? "success_msg" : "error_msg"
                }`}
              >
                {loginStatus}
              </div>
            )}

            <LoginSignupButton
              text="Login To Quiz Secure"
              onClick={handleFormSubmit}
            />
          </form>
        </div>
        <div className="createaccount">
          <div>
            Don't have an account?{" "}
            <button
              className="loginsignnup_btn"
              href=" "
              onClick={() => {
                props.onOpen();
                props.onClose();
              }}
            >
              Create a new account
            </button>
          </div>
        </div>
        <div className="separator">Or</div>
        <SocialOptions />
      </div>
    </div>
  );
}
