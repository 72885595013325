import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { faFile, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import ContentEditable from "react-contenteditable";
import "../../../assets/styles/components/addDocument.css";
import SelectFileUploadType from "../SelectFileUploadType";
import { useSelector } from "react-redux";
import {
  handleDeleteQuestion,
  handleQuestionSubmit,
} from "../../../utils/helpers";
import { actionCreators } from "../../../state";
import { getHTMLtoText } from "../../../utils/generalHelp";

export default function AddDocument(props) {
  const [propsValues, setPropsValues] = useState({ ...props.data });
  const setQuestions = useSelector((state) => state.setQuestions);
  const [addedFileFormat, setAddedFileFormat] = useState(
    props.data.options.available_file_type,
  );
  const isExplanationOn = useSelector((state) => state.isExplanationOn);
  const dispatch = useDispatch();

  const handleExplanationBlur = (event) => {
    handleQuestionSubmit(setQuestions, {
      explanation_text: event.target.innerHTML,
    });
  };

  const handleDescBlur = (event) => {
    handleQuestionSubmit(setQuestions, {
      options: {
        description: event.target.innerHTML,
        available_file_type: addedFileFormat,
      },
    });
  };

  const removeExtraCss = (element) => {
    let elementTree = element.getElementsByTagName("*");
    let res = "";
    const needed_css_rules = ["fontStyle", "fontWight"];

    for (let i = 0; i < elementTree.length; i++) {
      let ele = elementTree[i];
      if (ele.tagName === "INPUT") {
        continue;
      }
      let style_need = {};
      Object.entries(ele.style).map(([key, value]) => {
        if (needed_css_rules.includes(key)) {
          style_need[key] = value;
        }
        return null;
      });
      ele.removeAttribute("style");
      ele.removeAttribute("class");
      Object.entries(style_need).map(([key, value]) => {
        ele.style[key] = value;
        return null;
      });
      res = res + ele;
    }

    return res;
  };

  const handleOnPaste = (event, from) => {
    if (event.clipboardData.types.includes("Files")) {
      event.preventDefault();
      return;
    }
    if (event.clipboardData.types.includes("text/html")) {
      event.preventDefault();
      let value = event.clipboardData.getData("text/html");
      let tempElement = document.createElement("p");
      tempElement.innerHTML = value;
      removeExtraCss(tempElement);
      switch (from) {
        case "question_title":
          setPropsValues({
            ...propsValues,
            question_title: `<pre>${tempElement.innerHTML.trim()}</pre>`,
          });
          break;
        case "explanation_text":
          setPropsValues({
            ...propsValues,
            explanation_text: `<pre>${tempElement.innerHTML.trim()}</pre>`,
          });
          break;
        case "options":
          setPropsValues({
            ...propsValues,
            options: {
              ...propsValues["options"],
              [event.currentTarget.id]:
                `<pre>${tempElement.innerHTML.trim()}</pre>`,
            },
          });
          break;
        default:
          break;
      }
    }
  };

  const handleQuestionTitleChange = (event) => {
    try {
      let element = document.getElementById(`question_id-${setQuestions}`);
      element.innerText = event.currentTarget.innerText
        .trim()
        .replace(/\n/g, " ");
      element.parentElement.title = event.currentTarget.innerText.trim();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="upload-question-top-main">
        <div className="question-main-container add-doc-question">
          <div className="question-title">
            <div className="question-title-head">
              Upload File
              <span className="total-questions-of">1/10</span>
            </div>
            <ContentEditable
              onFocus={(event) =>
                window.getSelection().selectAllChildren(event.target)
              }
              disable={"false"}
              onBlur={(event) => {
                handleQuestionSubmit(setQuestions, {
                  question_title: event.target.innerHTML,
                });
              }}
              className="question-title-body"
              html={propsValues["question_title"]}
              onChange={(event) => {
                setPropsValues({
                  ...propsValues,
                  question_title: event.target.value,
                });
                handleQuestionTitleChange(event);
              }}
              tagName="div"
              placeholder="Enter your Upload a File Question here.."
              onPaste={(event) => {
                handleOnPaste(event, "question_title");
              }}
            />
            <FontAwesomeIcon
              icon={faTrashCan}
              className="question-delete-icon"
              size="xl"
              onClick={() => {
                handleDeleteQuestion(setQuestions);
              }}
            />
          </div>
          {getHTMLtoText(propsValues["question_title"]) === "" && (
            <p className="question-title-required">
              Please enter a title for your question to proceed
            </p>
          )}
          <div className="upload-img">
            <label className="btn-with-img" id="file-upload">
              <FontAwesomeIcon icon={faFile} size="xl" />
              Upload File
              {/* <input type="file" className="type-file-upload" id="file-upload" /> */}
            </label>
            <span className="file-size-limit"> File size limit: 100 MB</span>
          </div>
          <ContentEditable
            onFocus={(event) =>
              window.getSelection().selectAllChildren(event.target)
            }
            onBlur={handleDescBlur}
            disable={"false"}
            className="explanation-body"
            disabled={false}
            tagName="div"
            html={propsValues.options.description}
            onChange={(event) => {
              setPropsValues({
                ...propsValues,
                options: {
                  ...propsValues["options"],
                  description: event.target.value,
                },
              });
            }}
            placeholder="Type Your Description"
          />
          {isExplanationOn && (
            <div className="explanation">
              <div className="explanation-head">
                Add Explanation for Answer (Optional)
              </div>
              <ContentEditable
                onFocus={(event) =>
                  window.getSelection().selectAllChildren(event.target)
                }
                onBlur={handleExplanationBlur}
                disable={"false"}
                className="explanation-body"
                disabled={false}
                tagName="div"
                html={propsValues["explanation_text"]}
                onChange={(event) => {
                  setPropsValues({
                    ...propsValues,
                    explanation_text: event.target.value,
                  });
                }}
                placeholder="Type Explanation here..."
              />
            </div>
          )}
        </div>
        <SelectFileUploadType
          addedFileFormat={addedFileFormat}
          setAddedFileFormat={setAddedFileFormat}
          description={propsValues.options.description}
        />
      </div>
    </>
  );
}
